import {Component, Input, NgZone, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  Campaign,
  CampaignIntermediary,
  CampaignIntermediaryAction,
  CampaignIntermediaryActionService,
  CampaignIntermediaryPaginated,
  CampaignIntermediaryService,
  CampaignStatus,
  CodeTableEntry,
  IntermediaryType,
  ListParams,
  ListParamsWithSelection,
  UserInfo,
  UserInteraction,
  UserService
} from "../../../../api/core";
import {BehaviorSubject, combineLatest, filter, finalize, first, Observable, Subscription} from "rxjs";
import {DataService} from "../../../../services/data.service";
import {GridComponent, GridResetEvent} from "../../../../shared/grid/grid.component";
import {GridFilterModel} from "../../../../models/grid.model";
import {
  GridDataProvider,
  GridSelectedItemsProvider,
  GridSelectedItemsProviderByCollection
} from "../../../../shared/grid/data-source";
import {genHeaderCheckboxColumn, GridSelectionUtils} from "../../../../util/grid/grid-selection.util";
import {ActionType, CampaignActionListUtils, ContentAction} from "../campaign-actions-list-utils";
import {NotificationService} from "../../../../services/notification.service";
import {DialogHeight, DialogWidth, ModalService} from "../../../../services/modal.service";
import {PermissionService} from "../../../../services/permission.service";
import {CustomPreviewService} from "../../../../services/custom-preview.service";
import {IsCampaignEditablePipe} from "../../../../shared/shared.pipe";
import {
  CellClassParams,
  CellClickedEvent,
  CellEditRequestEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowClassParams,
  RowDoubleClickedEvent,
  RowSelectedEvent,
  SortChangedEvent
} from "ag-grid-community";
import {
  genCodeTableColumn,
  genEnumColumn,
  genTextColumn,
  genUserEnumColumn,
  usernameValueLabel
} from "../../../../util/grid/grid-renderer.util";
import {ECodeTables, EModalType, EPortfolioActionStatus} from "../../../../util/enum";
import {genDialogContentColumn} from "../../../../shared/grid/cell-renderers/dialog-content.renderer";
import {genActionLanguageColumn} from "../../../../shared/grid/cell-renderers/action-language.renderer";
import {EProtectedActions} from "../../../../util/protected-actions";
import {CodeTableService} from "../../../../services/code-table.service";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {genCustomContentColumn} from "../../../../shared/grid/cell-renderers/custom-content.renderer";
import {genActionStatus, genExecutionAction} from "../../../../shared/grid/cell-renderers/action-status.renderer";
import {ModalData} from "../../../../models/modal.model";
import {
  IntermediaryPortfoliosPopupComponent
} from "../../../../shared/intermediary-portfolios-popup/intermediary-portfolios-popup.component";
import {CampaignActionToolbarComponent} from "../campaign-action-toolbar/campaign-action-toolbar.component";
import {HtmlService} from "../../../../services/html.service";

@Component({
  selector: 'app-campaign-intermediary-list',
  templateUrl: './campaign-intermediary-list.component.html',
})
export class CampaignIntermediaryListComponent implements OnInit, OnDestroy {
  @ViewChild('grid')
  grid: GridComponent;

  @Input()
  gridSelectionUtils: GridSelectionUtils;
  @Input()
  actionToolbar: CampaignActionToolbarComponent;
  @Input()
  onExpandAll: Observable<void>;
  @Input()
  onCollapseAll: Observable<void>;

  selectionCount: number = 0;

  campaign: Campaign;
  searchValue: string;
  selectedSort: string;

  data: GridDataProvider;
  selectAllProvider: GridSelectedItemsProvider;
  selectAllByCollectionPreselectionProvider: GridSelectedItemsProviderByCollection;
  init = false;
  assignees: UserInfo[];
  initialAssignees: UserInfo[] = [];

  gridApi: GridApi;

  columnDefs: ColDef[] = [];
  detailColumnDefs: ColDef[] = [];

  gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    onRowSelected: (event: RowSelectedEvent) => {
      this.gridSelectionUtils.setMultipleSelection(
        event?.data?.actions,
        event?.node?.isSelected()
      );
    },
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) => this.toggleDetails(event.rowIndex),
    rowClassRules: {
      'ag-row-disabled': (params: RowClassParams<CampaignIntermediary>) => params.data && params.data.hasPendingActions === false,
      'ag-row-warn': (params: RowClassParams<CampaignIntermediary>) => params.data && this.hasClosedEntity(params.data),
    },
    onSortChanged: (event: SortChangedEvent) => {
      this.selectedSort = event.api.getColumn('id')?.getSort();
    },
    getRowId: (params) => params.data.id,
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      // provide the Grid Options to use on the Detail Grid
      detailGridOptions: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        onRowSelected: (event: RowSelectedEvent) => {
          this.gridSelectionUtils.setSelection(
            event?.data?.id,
            event?.data,
            event?.node?.isSelected()
          );
        },
        rowHeight: 36,
        suppressContextMenu: true,
        suppressCellFocus: true,
        readOnlyEdit: true,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        stopEditingWhenCellsLoseFocus: true,
        onCellEditRequest: (event) => this.handleCellEditRequest(event),
        columnDefs: this.detailColumnDefs,
        rowClassRules: {
          'ag-row-disabled': (params: RowClassParams<CampaignIntermediaryAction>) =>
            params.data && params.data.status !== EPortfolioActionStatus.pending,
          'ag-row-warn': (params: RowClassParams<CampaignIntermediaryAction>) =>
            params.data && params.data.employee.closed,
        },
      },
      // get the rows for each Detail Grid
      getDetailRowData: (params) => {
        params.successCallback(params.data.actions);
      },
    },
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
  };

  // intermediate subject that will communicate the grid filter model updates to the table-selection component
  filterModelSubject = new BehaviorSubject<GridFilterModel>({});
  private languages: CodeTableEntry[];
  private subscriptions: Subscription[] = [];
  private listUtils: CampaignActionListUtils;

  constructor(
    private readonly translateService: TranslateService,
    private readonly dataService: DataService,
    private readonly campaignIntermediaryActionService: CampaignIntermediaryActionService,
    private readonly notificationService: NotificationService,
    private readonly modalService: ModalService,
    private readonly permissionService: PermissionService,
    private readonly customPreviewService: CustomPreviewService,
    private readonly isCampaignEditablePipe: IsCampaignEditablePipe,
    private readonly codeTableService: CodeTableService,
    private readonly campaignIntermediaryService: CampaignIntermediaryService,
    private zone: NgZone,
    private readonly htmlService: HtmlService,
    private readonly userService: UserService,
  ) {
  }

  ngOnInit(): void {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.language),
    ]).subscribe(([languages]) => {
      this.languages = languages;
    });
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          if (!this.init) {
            this.data = this.fetchData.bind(this);
            this.selectAllProvider = (filter?: string, search?: string) => {
              return this.campaignIntermediaryActionService.getAllSelection(this.campaign.id, filter, search);
            }
          }
          this.listUtils = new CampaignActionListUtils(
            this.zone,
            this.campaign,
            this.notificationService,
            this.modalService,
            this.translateService,
            this.permissionService,
            this.isCampaignEditablePipe,
            this.customPreviewService,
            null,
            this.campaignIntermediaryActionService,
            this.userService,
          );
          this.gridSelectionUtils = new GridSelectionUtils(
            () => true,
            (data) =>
              data.extractedInfo
                ? data
                : {
                  id: data.id,
                  status: data.status,
                  employee: data.employee,
                  intermediaryId: data.intermediaryId,
                  action: data,
                  extractedInfo: true,
                }
          );
          this.columnDefs = this.generateColumnDefs();
          this.detailColumnDefs.push(...this.generateDetailColumnDefs());

          this.init = true;
          this.selectAllByCollectionPreselectionProvider =
            this.campaignIntermediaryActionService.getCampaignActionsSelectionByEmployeeCollection.bind(
              this.campaignIntermediaryActionService,
              this.campaign.id,
            );
          this.htmlService.waitFor(
            () => !!this.actionToolbar,
            () => {
              this.actionToolbar.options = {
                gridSelectionUtils: this.gridSelectionUtils,
                selectAllProvider: this.selectAllProvider,
                selectAllByCollectionPreselectionProvider: this.selectAllByCollectionPreselectionProvider,
                selectionCleared: () => this.gridApi && this.gridApi.deselectAll(),
                refreshTable: (force, redrawRows) => {
                  if (!this.grid) return;
                  this.grid.refresh();
                  this.onGridRefreshed();
                },
                selectionProcessing: loading => this.toggleOverlay(loading),
                searchText: () => this.searchValue,
              };
            }
          );
        }),
      this.permissionService.user$.subscribe(() => {
        if (this.gridApi) {
          // set timeout to let dynamic user update enabled/disabled
          setTimeout(() => {
            this.updateAssigneesBasedOnDynamicUsers();
            this.presetAssigneeFilter(this.gridApi);
          }, 0);
        }
      })
    );
    this.htmlService.waitFor(
      () => !!this.onExpandAll,
      () => this.subscriptions.push(
        this.onExpandAll.subscribe(() => {
          if (!this.grid) return;
          this.grid.resetExpandedRows();
          this.expandAll(true)
        }),
      )
    );
    this.htmlService.waitFor(
      () => !!this.onCollapseAll,
      () => this.subscriptions.push(
        this.onCollapseAll.subscribe(() => {
          if (!this.grid) return;
          this.grid.resetExpandedRows();
          this.expandAll(false)
        })
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  gridFilterReset(event: GridResetEvent) {
    this.presetAssigneeFilter(event.api);
  }

  private gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.htmlService.waitFor(
      () => !!this.actionToolbar,
      () => this.actionToolbar.gridApi = event.api
    );
    // force filter-preset
    this.gridApi.showLoadingOverlay();
    this.fetchAssignees({
      success: () => {
        this.presetAssigneeFilter(event.api);
        this.gridApi.hideOverlay();
      },
    });
  }

  private presetAssigneeFilter(gridApi: GridApi) {
    const currentUsernames = [
      this.permissionService.userRoleData.username,
      ...this.permissionService.userRoleData.dynamicUsers
        .filter(u => u.enabled)
        .map(u => u.username)
    ].filter(Boolean);
    this.listUtils.setAssigneeFilter(
      gridApi,
      currentUsernames,
      'actions.assignees.username',
      this.assignees,
      currentUsernames // we are sending the same list as we don't have a predefined list of assignees as it's done with action-list and portfolio-list
    );
  }

  private fetchData(
    gridParams: ListParams
  ): Observable<CampaignIntermediaryPaginated> {
    const params = {
      ...gridParams,
      selectedItems: this.selectedSort
        ? this.gridSelectionUtils.getSelectedValues().map((item) => item.id)
        : [],
      selectedSort: this.selectedSort,
    } as ListParamsWithSelection;
    this.toggleOverlay(true)
    return this.campaignIntermediaryActionService.getCampaignIntermediaryActionsGrouped(
      this.campaign.id,
      params
    ).pipe(finalize(() => this.toggleOverlay(false)));
  }

  private hasClosedEntity(source: CampaignIntermediary): boolean {
    return (
      source.closed ||
      source.actions.some((a) => a.employee.closed)
    );
  }

  private generateColumnDefs(): ColDef[] {
    const isEditableCampaign = this.isCampaignEditablePipe.transform(this.campaign.status);
    return [
      isEditableCampaign ? {
      ...genHeaderCheckboxColumn(
        this.gridSelectionUtils,
        true, 0,
        'id',
        true,
        this.translateService.instant('sortBySelected'),
        ),
      lockVisible: true,
      } : undefined,
      {
        ...genTextColumn(
          'key',
          this.translateService.instant('key'),
          null,
          {
            customPath: 'intermediary.externalKey'
          }
        ),
        cellRenderer: 'agGroupCellRenderer',
        sortable: true,
      },
      genTextColumn(
        'name',
        this.translateService.instant('name'),
        null,
        {
          customPath: 'intermediary.name'
        }
      ),
      genEnumColumn({
        field: 'type',
        values: [IntermediaryType.EAM, IntermediaryType.EWA],
        headerName: this.translateService.instant('type'),
        filterParamsInfo: {
          customPath: 'intermediary.type',
        }
      }),
      genCodeTableColumn({
        field: 'intermediary.businessUnit',
        dtoField: 'businessUnit',
        headerName: this.translateService.instant('businessUnit'),
        observable: this.codeTableService.getCodeTable(ECodeTables.businessUnit),
      }),
      {
        ...genUserEnumColumn(
          'actions.assignees.username',
          this.translateService.instant('assignedTo'),
          this.fetchAssignees.bind(this),
          () => this.assignees
        ),
        valueFormatter: (r) => {
          const assignees = r.data.assignees;
          return assignees.map(u => usernameValueLabel(u)).join(', ');
        },
        sortable: false, // removed the sortable because we have a limitation with N to N sorting relationships
      },
      {
        ...genIconButtonColumn({
          callback: (data: CampaignIntermediary) => {
            this.refreshSuitability(data);
          },
          tooltip: this.translateService.instant('updateSuitability'),
          icon: 'sync',
          hidden: (data: CampaignIntermediary) =>
            data.type === IntermediaryType.EAM
            || !data.hasPendingActions
            || !this.permissionService.hasAnyPermission(
              EProtectedActions.refreshSingleSuitabilityCampaign
            ),
        }),
        lockVisible: true,
      },
      {
        ...genIconButtonColumn({
          headerName: this.translateService.instant('portfolios'),
          icon: 'view',
          tooltip: this.translateService.instant('showPortfolios'),
          callback: (cIntermediary: CampaignIntermediary) => this.showPortfolios(cIntermediary),
          hidden: (cIntermediary: CampaignIntermediary) => cIntermediary.type !== IntermediaryType.EWA,
        }),
        minWidth: 150,
        maxWidth: 150,
        sortable: false,
      }
    ].filter(t => t);
  }

  private generateDetailColumnDefs(): ColDef[] {
    let cols = [
      {
        ...genTextColumn(
          'employee.name',
          this.translateService.instant('employeeName'),
          null
        ),
        floatingFilter: false,
      },
      this.generateLanguageColumn(),
      this.generateChannelColumn(),
      this.generateContentColumn(),
      this.generateSenderColumn(),
      ...this.genActionStatusColumnDef(),
    ];
    if (this.isCampaignEditablePipe.transform(this.campaign.status)) {
      cols.splice(
        0,
        0,
        genHeaderCheckboxColumn(this.gridSelectionUtils, true, 1)
      );
    }
    return cols.filter((col) => col);
  }

  private genActionStatusColumnDef(): ColDef[] {
    const isEditableCampaign = this.isCampaignEditablePipe.transform(this.campaign.status)
    if (this.campaign.status === CampaignStatus.LAUNCHED) {
      return [
        {
          ...genActionStatus(
            this.translateService,
            'status',
            this.translateService.instant('status'),
            isEditableCampaign
          ),
          floatingFilter: false,
          suppressHeaderMenuButton: true,
        },
        {
          ...genExecutionAction(
            this.translateService,
            'executionAction',
            this.translateService.instant('executedAction')
          ),
          floatingFilter: false,
        },
        {
          ...genTextColumn(
            'executionUser.username',
            this.translateService.instant('executedBy'),
            d => usernameValueLabel(d.data.executionUser)),
          floatingFilter: false,
          suppressHeaderMenuButton: true,
        }
      ];
    }
    return [];
  }

  private generateContentColumn(): ColDef {
    if (this.campaign.status === CampaignStatus.LAUNCHED) {
      return {
        ...genCustomContentColumn({
          field: 'hasContentOverride',
          headerName: this.translateService.instant('content'),
          hidden: (data) =>
            this.campaign.contents?.length === 0 ||
            !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent) ||
            !data.hasCidPermission,
          callback: (data: CampaignIntermediaryAction, action: string) => {
            const contentAction: ContentAction = {
              id: data.id,
              type: ActionType.IntermediateAction,
              campaignId: this.campaign.id,
              language: data.language,
              channel: data.channel,
              content: data.content,
            };
            this.listUtils.handleCustomContentAction(contentAction, action, true, this.updateAction.bind(this));
          },
        }),
        floatingFilter: false,
        suppressHeaderMenuButton: true,
      };
    }
    return undefined;
  }

  private generateChannelColumn(): ColDef {
    if ([CampaignStatus.LAUNCHED].includes(this.campaign.status)) {
      return {
        ...genDialogContentColumn({
          field: 'channel.type.name',
          labelValue: (data: CampaignIntermediaryAction) => data.channel?.type?.name,
          headerName: this.translateService.instant('channel'),
          iconGetter: (data) => (data.content || data.status !== EPortfolioActionStatus.pending) ? 'view' : 'edit_m',
        }),
        floatingFilter: false,
        suppressHeaderMenuButton: true,
        onCellClicked: (event: CellClickedEvent) => {
          this.listUtils.handleChannelCellClick(
            event.data, ActionType.IntermediateAction, this.updateAction.bind(this), true
          );
        },
        cellClass: (params: CellClassParams) => 'editable-cell',
      }
    }
    return undefined;
  }

  private generateLanguageColumn(): ColDef {
    if ([CampaignStatus.LAUNCHED].includes(this.campaign.status)) {
      return {
        ...genActionLanguageColumn({
          field: 'language.name',
          headerName: this.translateService.instant('language'),
          campaignStatus: this.campaign.status
        }),
        floatingFilter: false,
        suppressHeaderMenuButton: true,
        singleClickEdit: true,
        editable: (params: any) => this.isDetailLangCellEditable(params),
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) =>
          this.listUtils.getLanguageEditParams(params),
        cellClass: (params: any) =>
          this.isDetailLangCellEditable(params) ? 'editable-cell' : '',
      }
    } else {
      return {
        ...genTextColumn(
          this.campaign.status === CampaignStatus.FROZEN ? 'employee.preferredLanguage.name' : 'language.name',
          this.translateService.instant('language')
        ),
        floatingFilter: false,
        suppressHeaderMenuButton: true,
      }
    }
  }

  private generateSenderColumn(): ColDef {
    if ([CampaignStatus.LAUNCHED].includes(this.campaign.status)) {
      return {
        ...
          genDialogContentColumn({
            field: 'sender.username',
            labelValue: d => usernameValueLabel(d.sender),
            headerName: this.translateService.instant('sender'),
          }),
        floatingFilter: false,
        suppressHeaderMenuButton: true,
        onCellClicked:
          (event: CellClickedEvent) => {
            this.listUtils.handleSenderCellClick(event.data, ActionType.IntermediateAction, this.updateAction.bind(this));
          },
        cellClass:
          (params: CellClassParams) =>
            this.listUtils.isSenderCellEditable(params.data)
              ? 'editable-cell'
              : '',
      }
    } else {
      return {
        ...genTextColumn(
          'sender.username',
          this.translateService.instant('sender'),
          d => usernameValueLabel(d.data.sender)),
        floatingFilter: false,
        suppressHeaderMenuButton: true,
      }
    }
  }

  onGridRefreshed(): void {
    this.expandAll(false);
  }

  /**
   * Triggered by HTML. Expand or collapse all details
   * @param expand Whether to expand or collapse all details
   */
  expandAll(expand: boolean): void {
    this.toggleOverlay(true);
    this.gridApi.forEachNode((rowNode) => {
      rowNode.setExpanded(expand);
    });
    setTimeout(() => (this.toggleOverlay(false)), 0);
  }

  toggleOverlay(loading: boolean) {
    if (loading) {
      this.gridApi.showLoadingOverlay();
    } else {
      this.gridApi.hideOverlay();
    }
  }

  private toggleDetails(index: number): void {
    const isExpanded = this.gridApi.getDisplayedRowAtIndex(index).expanded;
    this.gridApi.getDisplayedRowAtIndex(index).setExpanded(!isExpanded);
  }

  refreshGrid(purgeData: boolean = false): void {
    this.grid?.refresh(purgeData);
  }

  private isDetailLangCellEditable(params: any): boolean {
    const hasContents = this.campaign.contents?.length > 0;
    return (
      hasContents &&
      this.permissionService.hasAnyPermission(
        EProtectedActions.editActionLanguage
      ) &&
      !params.data.content &&
      this.permissionService.hasPermissionForCampaignOverview(this.campaign) &&
      params.data.status === EPortfolioActionStatus.pending
    );
  }

  private handleCellEditRequest(event: CellEditRequestEvent): void {
    if (event.colDef.field === 'language.name') {
      if (event.newValue !== event.oldValue) {
        const newLanguage = this.languages.find(
          (language) => language.name === event.newValue
        );
        this.toggleOverlay(true);
        this.campaignIntermediaryActionService
          .updateAction(event.data.id, {
            id: event.data.id,
            languageCode: newLanguage.ident,
          })
          .pipe(first())
          .subscribe({
            next: (data: CampaignIntermediaryAction) => this.updateAction(data),
            complete: () => (this.toggleOverlay(false)),
          });
        this.userService.recordUserInteraction(
          newLanguage.ident == 'en' ? UserInteraction.CHANGEDCAMPAIGNINTERMEDIARYACTIONLANGUAGETOEN
            : newLanguage.ident == 'de' ? UserInteraction.CHANGEDCAMPAIGNINTERMEDIARYACTIONLANGUAGETODE
              : UserInteraction.CHANGEDCAMPAIGNINTERMEDIARYACTIONLANGUAGETOOTHER
        ).pipe(first()).subscribe();
      }
    }
  }

  private updateAction(data: CampaignIntermediaryAction): void {
    // since this is a hierarchical grid, we need to refresh the whole grid
    if (data) {
      this.refreshGrid();
    }
  }

  private showPortfolios(cIntermediary: CampaignIntermediary) {
    const data = {
      status: this.campaign.status,
      campaignIntermediaryId: cIntermediary.id,
      intermediaryId: cIntermediary.intermediaryId,
      campaignIntermediary: cIntermediary,
      canCollapse: this.campaign.status === CampaignStatus.FROZEN || this.campaign.status === CampaignStatus.LAUNCHED,
      portfoliosGetter: this.campaignIntermediaryService.getPortfolios.bind(this.campaignIntermediaryService, cIntermediary.id),
    };
    const modalData: ModalData = {
      type: EModalType.showCampaignIntermediaryPortfolios,
      title: this.translateService.instant('intermediaryPortfolios'),
      data: data,
      component: IntermediaryPortfoliosPopupComponent,
    }
    const modalRef = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      false,
      false,
      DialogWidth.AUTO,
      DialogHeight.AUTO,
    );
    modalRef.afterClosed().subscribe((data) => {
      if (data === 'suitabilityRefreshed' || data === 'allPortfoliosDeleted') {
        this.refreshGrid();
      }
    });
  }

  private refreshSuitability(data: CampaignIntermediary) {
    this.campaignIntermediaryService.refreshSuitabilities(data.id).subscribe(() => {
      this.notificationService.handleSuccess(
        this.translateService.instant('refreshSelectedSuitabilitySuccess')
      );
      this.refreshGrid();
    });
  }

  private fetchAssignees(params: any) {
    if (this.assignees) {
      params.success(this.assignees.map((d) => d.username));
    } else {
      this.campaignIntermediaryActionService
        .getAssignees(this.campaign.id)
        .subscribe((data) => {
          this.assignees = data;
          this.initialAssignees = this.assignees;
          this.updateAssigneesBasedOnDynamicUsers();
          params.success(this.assignees.map((d) => d.username));
        });
    }
  }

  private updateAssigneesBasedOnDynamicUsers() {
    const curDynamicUsers: UserInfo[] = this.permissionService.userRoleData.dynamicUsers
      .filter(u => u.enabled).filter(Boolean)
      .map(u => ({id: u.id, username: u.username, fullname: u.fullname}));
    this.assignees = this.initialAssignees.concat(
      curDynamicUsers.filter(u => !this.initialAssignees.find(i => i.username === u.username)));
  }

  selectAllClicked(selected: boolean) {
    this.actionToolbar?.selectAllClicked(selected);
  }

  protected readonly ActionType = ActionType;
}
