import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {
  CodeTableEntry,
  Content,
  ContentAttachment, ContentDefinition,
  FieldDefinition, FieldDefinitionType,
  Gui,
} from 'src/app/api/core';
import {EFormStatus, EFormValidators,} from 'src/app/util/enum';
import {PermissionService} from 'src/app/services/permission.service';
import {Observable, Subscription} from 'rxjs';
import {EProtectedActions} from '../../util/protected-actions';
import {TranslateService} from "@ngx-translate/core";
import {FroalaEditorService} from "../../services/froala-editor.service";

/**
 * Component to content form
 */
@Component({
  selector: 'app-content-form-fields',
  templateUrl: './content-form-fields.component.html',
})
export class ContentFormFieldsComponent implements OnInit, OnDestroy {
  @Input()
  parentId: number;
  @Input()
  language: CodeTableEntry;
  @Input()
  isCustomContent: boolean;
  @Input()
  isEditable: boolean;
  @Input()
  content: Content;
  @Input()
  attachments: ContentAttachment[];
  @Input()
  guiConfig: Gui;
  @Input()
  contentDefinitionObservable: Observable<ContentDefinition>;
  contentForm: FormGroup;
  @Output()
  formValidityUpdate = new EventEmitter<boolean>();
  formStatusSubscription: Subscription;
  showWarning = false;
  canEdit: boolean;
  warningMessage: string;
  disabled: boolean;
  fromBuilt: boolean = false;

  fieldDefinitions: FieldDefinition[];

  label = this.translationService.currentLang === 'de' ? 'labelDe' : 'labelEn';

  constructor(
    private fb: FormBuilder,
    private permissionService: PermissionService,
    private translationService: TranslateService,
    private froalaEditorService: FroalaEditorService,
  ) {
  }

  ngOnInit(): void {
    this.canEdit = this.permissionService.hasAnyPermission(
      EProtectedActions.editCampaignContents,
      EProtectedActions.editStoryContents,
      EProtectedActions.editCampaignCustomContent,
    );

    this.contentDefinitionObservable.subscribe((cd) => {
      this.fieldDefinitions = cd.orderedFieldDefinitions
        .sort((a, b) => a.orderValue - b.orderValue)
        .map(t => t.fieldDefinition);

      this.buildForm();

      this.disabled = !this.isEditable;
      if (this.disabled) {
        setTimeout(() => {
          this.contentForm.disable();
        });
      }

      if (this.content) {
        this.contentForm.patchValue({
          ...this.withValuesFromContent(this.content),
        });
        this.warningMessage = 'warningWrongLanguage';
      } else {
        this.warningMessage = 'warningNoContent';
      }

      this.showWarning =
        this.isCustomContent &&
        this.language.id !== this.content?.language.id;
      this.formStatusSubscription = this.contentForm.statusChanges.subscribe(
        (status) => {
          this.formValidityUpdate.emit(status === EFormStatus.VALID);
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.formStatusSubscription?.unsubscribe();
  }

  private buildForm(): void {
    // if fields are not yet loaded, do not build the form
    if (!this.fieldDefinitions) return;
    this.contentForm = this.fb.record({})
    this.fieldDefinitions.forEach((fd) => {
      let formControl = new FormControl({
        value: null,
        disabled: this.isContentFieldDisabled(fd.ident, fd.customEditable),
      });
      if (fd.mandatory) {
        formControl.setValidators([
          Validators.required,
          Validators.pattern(EFormValidators.textFieldNotBlankPattern),
        ]);
      }
      if (fd.maxLength != null && fd.maxLength > 0) { // consider 0-length as no limit
        formControl.addValidators([
          Validators.maxLength(fd.maxLength)
        ]);
      }
      this.contentForm.registerControl(fd.ident, formControl);
    });
    this.fromBuilt = true;
  }

  private isContentFieldDisabled(field: string, customEditable: boolean): boolean {
    // Currently, the permissions are hard coded to INTRO and OUTRO fields. With the introduction of dynamic fields,
    // this might need to be updated.
    if (field === 'INTRO' || field === 'OUTRO') {
      const canEditIntroOutro = this.permissionService.hasAnyPermission(
        EProtectedActions.editCampaignIntroOutro,
        EProtectedActions.editStoryIntroOutro,
      );
      return !canEditIntroOutro && (
        !this.canEdit || (this.isCustomContent && !customEditable)
      );
    }
    return !this.canEdit || (this.isCustomContent && !customEditable);
  }

  /**
   * Creates a new object with the values from the content, then this will be
   * used to update the values from the form.
   * @param content
   */
  withValuesFromContent(content: Content): any {
    const result = {} as any;
    for (const field of content.fields) {
      result[field.type] = field.value;
    }
    return result;
  }

  /**
   * Creates a new content object with the updated values from the input.
   * @param content
   * @param input
   */
  withUpdatedContentValues(content: Content, input: any): Content {
    const result = content ?
      JSON.parse(JSON.stringify(content)) as Content
      : {fields: []} as Content;
    for (const [key, value] of Object.entries(input)) {
      const field = result.fields.find((f) => f.type === key);
      const valStr = (value as string) || '';
      if (field) {
        field.value = valStr;
      } else {
        result.fields.push(
          {id: null, isRichText: this.isRichText(key), type: key, value: valStr, mandatory: this.isMandatory(key)}
        );
      }
    }
    return result;
  }

  isRichText(fieldType: string) {
    return this.fieldDefinitions.find((f) =>
      f.ident === fieldType)?.editorType === FieldDefinitionType.RICHTEXT;
  }

  isMandatory(fieldType: string) {
    return this.fieldDefinitions.find((f) =>
      f.ident === fieldType)?.mandatory;
  }

  isVisible(fd: FieldDefinition) {
    if (this.isCustomContent) {
      return fd.showInCustom;
    }
    return fd.showInGeneral;
  }

  protected readonly FieldDefinitionType = FieldDefinitionType;

  getEditorOptions(fd: FieldDefinition) {
    return this.froalaEditorService.getEditorOptions(fd);
  }
}
