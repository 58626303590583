import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, first} from 'rxjs/operators';
import {
  Campaign,
  CampaignService, CampaignStatus,
  CampaignUpdate,
  CodeTableEntry,
  ExternalLink,
} from 'src/app/api/core';
import {ModalData, ModalSubComponent} from 'src/app/models/modal.model';
import {codeTableEntries, CodeTableService} from 'src/app/services/code-table.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { MAX_DATE, MIN_DATE } from 'src/app/util/date-formatter';
import {
  ECodeTables,
  EFormStatus,
  EFormValidators,
  EModalType,
} from 'src/app/util/enum';
import { PermissionService } from '../../../../services/permission.service';
import { EProtectedActions } from '../../../../util/protected-actions';
import {TranslateService} from "@ngx-translate/core";
import {ModalService} from "../../../../services/modal.service";
import {NotificationService} from "../../../../services/notification.service";

/**
 * Component to show all stories on one page.
 */
@Component({
  selector: 'app-campaign-details-form',
  templateUrl: './campaign-details-form.component.html',
})
export class CampaignDetailsFormComponent
  implements OnInit, OnDestroy, ModalSubComponent
{
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  campaign: CampaignUpdate;
  externalLinks: ExternalLink[] = [];
  campaignForm = this.fb.group({
    id: [null],
    name: [
      '',
      [
        Validators.required,
        Validators.maxLength(EFormValidators.textFieldMaxLength),
        Validators.pattern(EFormValidators.textFieldNotBlankPattern),
      ],
    ],
    useCase: [null as CodeTableEntry],
    publicationType: [
      null as CodeTableEntry,
      [Validators.required]
    ],
    hub: [
      null as CodeTableEntry,
      [Validators.required]
    ],
    validFrom: [null],
    validTo: ['', Validators.required],
    info: '',
    externalLinks: [[]]
  }, {
    validators: this.externalLinksValidator.bind(this)
  });
  minDate = MIN_DATE;
  maxDate = MAX_DATE;
  useCases: CodeTableEntry[] = [];
  publicationTypes: CodeTableEntry[] = [];
  hubs: CodeTableEntry[] = [];
  canEditInfo = false;
  canEditNotDraft = false;
  canEditHub = false;

  private subscriptions: Subscription[] = [];
  private readonly storyId: number;
  private readonly isDecentralized: boolean;

  constructor(
    private fb: FormBuilder,
    protected readonly campaignService: CampaignService,
    protected readonly globalService: GlobalService,
    protected readonly codeTableService: CodeTableService,
    protected readonly datePipe: DatePipe,
    protected readonly dataService: DataService,
    protected readonly translateService: TranslateService,
    protected readonly modalService: ModalService,
    protected readonly notificationService: NotificationService,
    protected permissionService: PermissionService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        storyId: number;
        campaign: CampaignUpdate;
        status: CampaignStatus;
        decentralized: boolean;
      };
    }
  ) {
    this.isDecentralized = data.data.decentralized;
    this.canEditNotDraft = data.data.status === CampaignStatus.DRAFT;
    this.canEditInfo = this.permissionService.hasAnyPermission(
      EProtectedActions.editCampaignInfo
    );
    [
      this.campaignForm.get('useCase'),
      this.campaignForm.get('publicationType'),
    ].forEach(field => {
      if (this.canEditNotDraft) {
        field.enable();
      }else {
        field.disable();
      }
    });

    this.handleCampaign(data.data.campaign);
    this.storyId = data.data.storyId;
    this.initCodeTables();
    this.canEditHub = data.data.status === CampaignStatus.DRAFT && !this.storyId && this.hubs.length > 1;
  }

  ngOnInit(): void {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled =
      !this.campaignForm.controls.validTo.valid;

    // Mark publicationType as touched to show required error
    this.campaignForm.controls.publicationType.markAsTouched({onlySelf: true});
    // Mark validTo as touched to show required error
    this.campaignForm.controls.validTo.markAsTouched({onlySelf: true});

    this.subscriptions.push(
      this.campaignForm.statusChanges.subscribe(
        (status) =>
          (this.dialogRef.componentInstance.toolbarActionData.btnDisabled =
            status === EFormStatus.INVALID)
      )
    );
    this.subscriptions.push(
      this.campaignForm.controls.validFrom.valueChanges.subscribe((value) => {
        const isInvalid = !this.campaignForm.controls.validFrom.valid;
        const parseErrorText: string =
          this.campaignForm.controls.validFrom.errors?.matDatepickerParse?.text;
        if (value === null && isInvalid && parseErrorText === '') {
          // remove matDatepickerParse error for empty string since field is not required
          this.campaignForm.controls.validFrom.reset(null, {
            emitEvent: false,
          });
        }
      })
    );
    this.subscriptions.push(
      this.campaignForm.controls.validTo.valueChanges.subscribe((value) => {
        const isInvalid = !this.campaignForm.controls.validTo.valid;
        const parseErrorText: string =
          this.campaignForm.controls.validTo.errors?.matDatepickerParse?.text;
        if (value === null && isInvalid && parseErrorText === '') {
          // remove matDatepickerParse error for empty string since field is not required
          this.campaignForm.controls.validTo.reset(null, { emitEvent: false });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get formValidators() {
    return EFormValidators;
  }

  /**
   * Triggered by parent component
   */
  modalAction(modalType: EModalType): void {
    if (this.campaignForm.valid) {
      if (this.campaign?.id && this.campaign.hub.ident !== this.campaignForm.value.hub.ident) {
        this.modalService.openConfirmationDialog({
          title: this.translateService.instant('changeHubTitle'),
          type: EModalType.confirmationDialog,
          data: {
            message: this.translateService.instant('changeHubMessage'),
          },
          component: null,
          submitBtn: {
            label: this.translateService.instant('yes'),
            callback: (modalRef: MatDialogRef<ModalComponent>) => {
              modalRef.close(true);
              this.proceedWithModalAction(modalType);
            },
          },
          cancelBtn: {
            label: this.translateService.instant('cancel'),
            callback: (modalRef: MatDialogRef<ModalComponent>) => {
              modalRef.close(false);
              this.dialogRef.componentInstance.resetToolbarActionButtons();
            }
          },
        } as ModalData);
      } else {
        this.proceedWithModalAction(modalType);
      }
    }
  }

  private proceedWithModalAction(modalType: EModalType) {
    switch (modalType) {
      case EModalType.editCampaign:
        const newCampaign: CampaignUpdate = {
          ...this.campaignForm.getRawValue(), // TODO check if this is correct (rawValue might return too much data)
          externalLinks: this.externalLinks,
          decentralized: this.isDecentralized,
        };
        newCampaign.validFrom = newCampaign.validFrom
          ? this.datePipe.transform(newCampaign.validFrom, 'yyyy-MM-dd')
          : null;
        newCampaign.validTo = this.datePipe.transform(
          newCampaign.validTo,
          'yyyy-MM-dd'
        );
        this.dataService.updateLoading(true);
        this.getObservable(newCampaign)
          .pipe(
            first(),
            catchError((err, caught) => {
              const errorMessage = err?.error?.error;
              this.notificationService.handleError(errorMessage);
              return of(false);
            }),
            finalize(() => {
              this.dataService.updateLoading(false);
              this.dialogRef.componentInstance.resetToolbarActionButtons();
            })
          )
          .subscribe((campaign) => {
            if (campaign === false) return;
            this.dataService.updateCampaign(campaign as Campaign);
            this.dialogRef.close({ success: true, campaign });
          });
        break;
    }
  }

  private handleCampaign(campaign: CampaignUpdate): void {
    if (campaign) {
      this.campaign = campaign;
      this.campaignForm.patchValue({
        id: campaign.id,
        name: campaign.name,
        validFrom: campaign.validFrom,
        validTo: campaign.validTo,
        info: campaign.info,
      });
      this.externalLinks = [...(campaign?.externalLinks || [])].map(l => ({...l}));
    }
  }

  private getObservable(newCampaign: CampaignUpdate) {
    let result: Observable<Campaign>;
    if (newCampaign.id) {
      result = this.campaignService.updateCampaign(newCampaign);
    } else if (this.storyId) {
      result = this.campaignService.createCampaignFromStory(
        this.storyId,
        newCampaign
      );
    } else {
      result = this.campaignService.createCampaignWithoutStory(newCampaign);
    }
    return result;
  }

  private initCodeTables() {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.useCase),
      this.codeTableService.getCodeTable(ECodeTables.publicationType)
    ]).subscribe(([useCases, publicationTypes]) => {
      // use cases
      const useCase = useCases.find((u) => u.id === this.campaign?.useCase?.id);
      this.useCases = codeTableEntries(useCases, useCase);
      this.campaignForm.patchValue({ useCase });
      // publication types
      const publicationType = publicationTypes.find((pt) => pt.id === this.campaign?.publicationType?.id);
      this.publicationTypes = codeTableEntries(publicationTypes, publicationType);
      this.campaignForm.patchValue({ publicationType });
      // hubs
      const userHubs = this.globalService.getCurrentUserData().hubs;
      const hub = userHubs
        .find((h) => h.ident === this.campaign?.hub?.ident) || userHubs[0];
      this.hubs = codeTableEntries(userHubs, hub);
      this.campaignForm.patchValue({ hub });
    })
  }

  protected updateCodeTables() {
    const values = this.campaignForm.value;
    this.useCases = codeTableEntries(this.useCases, values.useCase);
    this.publicationTypes = codeTableEntries(this.publicationTypes, values.publicationType);
    this.hubs = codeTableEntries(this.hubs, values.hub);
  }

  handleExternalLinksChanged(links: ExternalLink[]) {
    this.externalLinks = links;
    this.campaignForm.updateValueAndValidity();
  }
  externalLinksValidator(fb: FormGroup): ValidationErrors | null {
    if (!this?.externalLinks) return null;
    const valid = (this?.externalLinks||[]).every(l => !!l.url);
    if (valid) return  null;
    return  {
      externalLinks: true,
    };
  }
}
