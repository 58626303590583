import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {Campaign, ContentService, ExternalLink, Product} from "../../../../api/core";
import {DataService} from "../../../../services/data.service";
import {filter, Subscription} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {ECodeTables, EViewRoutes} from "../../../../util/enum";
import {CAMPAIGN_COMPACT_ADDITIONAL_INFORMATION, CAMPAIGN_COMPACT_PRODUCT_TABS} from "../../../../util/tab.constants";
import {Tab} from "../../../../models/tabs.model";
import {TranslateService} from "@ngx-translate/core";
import {ColDef, GridOptions} from "ag-grid-community";
import {genCodeTableColumn, genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {CodeTableService} from "../../../../services/code-table.service";
import {GlobalService} from "../../../../services/global.service";
import {PermissionService} from "../../../../services/permission.service";
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";

type InternalLink = ExternalLink & {
  icon: string;
}

@Component({
  selector: 'app-campaign-compact-content',
  templateUrl: './campaign-compact-content.component.html'
})
export class CampaignCompactContentComponent implements OnInit, OnDestroy {
  linksStyle: string;

  @Output()
  next = new EventEmitter<void>();

  campaign: Campaign;
  htmlStr: SafeHtml;
  tabs: Tab[] = CAMPAIGN_COMPACT_PRODUCT_TABS;
  defaultTab = this.tabs[0].text;
  activeTab = this.defaultTab;
  additionalTabs: Tab[] = [...CAMPAIGN_COMPACT_ADDITIONAL_INFORMATION];
  additionalDefaultTab = this.additionalTabs[0].text;
  additionalActiveTab = this.additionalDefaultTab;
  products: Product[] = [];
  links: InternalLink[] = [];
  attachments: InternalLink[] = [];
  externalLinks: InternalLink[] = [];
  productsColumns: ColDef[] = [
    genTextColumn(
      'name',
      this.translateService.instant('productName'),
    ),
    genLinkColumn({
        field: 'isin',
        headerName: this.translateService.instant('isin'),
        link: (data: Product) => data.link? data.link : null,
        externalLink: true,
    }),
    genCodeTableColumn({
      field: 'assetClass',
      headerName: this.translateService.instant('class'),
      observable: this.codeTableService.getCodeTable(ECodeTables.assetClass),
    }),
    genTextColumn(
      'currency.ident',
      this.translateService.instant('currency'),
    ),
  ]. map(c => ({...c, suppressMovable: true}));
  productsGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    rowMultiSelectWithClick: true,
    paginationAutoPageSize: true,
  };
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly dataService: DataService,
    private readonly routerService: Router,
    private readonly sanitizer: DomSanitizer,
    private readonly contentService: ContentService,
    private readonly translateService: TranslateService,
    private readonly codeTableService: CodeTableService,
    private readonly globalService: GlobalService,
    readonly permissionService: PermissionService
  ) {
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.links = this.externalLinks = this.getExternalLinks(campaign.externalLinks || []);
          this.additionalTabs[1].hidden = !this.links.length;
          this.additionalTabs[2].hidden = !this.attachments.length;
          this.onTabChanged(this.defaultTab);
          this.adjustSizes();
          this.updatePreview();
        })
    );
  }

  ngOnInit() {
    this.updateProductCount();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async onBack() {
    await this.routerService.navigate([EViewRoutes.home])
  }

  onNext() {
    this.next.next(undefined);
  }

  onTabChanged(tab: string): void {
    this.activeTab = tab;
    this.products = (tab == this.tabs[0].text ? this.campaign.buyProducts : this.campaign.sellProducts) || [];
  }
  onAdditionalTabChange(tab: string) {
    this.additionalActiveTab = tab;
    setTimeout(() => this.adjustSizes(), 100);
  }
  get validPeriod() {
    return this.globalService.formatDatePeriod(
      this.campaign.validFrom,
      this.campaign.validTo
    );
  }

  private updateProductCount() {
    this.tabs.find(tab => tab.icon === 'buy').suffix = `(${this.campaign.buyProducts.length})` ?? '';
    this.tabs.find(tab => tab.icon === 'sell').suffix = `(${this.campaign.sellProducts.length})` ?? '';
  }

  private updatePreview() {
    this.contentService.getCampaignOverviewContentPreview(this.campaign.id)
      .subscribe(result => {
        const preview = result.preview || this.getErrorPreview()
        this.htmlStr = this.sanitizer.bypassSecurityTrustHtml(preview);
        this.links = [...this.externalLinks];
        this.attachments = [...this.getAttachmentLinks(result.links || [])];
        this.additionalTabs[1].hidden = !this.links.length;
        this.additionalTabs[2].hidden = !this.attachments.length;
        this.adjustSizes();
      });
  }

  private getExternalLinks(src: ExternalLink[]): InternalLink[] {
    return src.map(link => ({...link, icon: 'external_link'}));
  }

  private getAttachmentLinks(src: ExternalLink[]): InternalLink[] {
    const icons = {
      default: 'file',
      pdf: 'pdf',
      jpg: 'image',
      jpeg: 'image',
      png: 'image',
      mp4: 'movie',
      avi: 'movie',
    }
    return src.map(link => ({...link, icon: icons[this.getExt(link.display)] || icons.default}));
  }

  private getExt(filename: string): string {
    return  filename.split('.').slice(-1)[0].toLowerCase();
  }

  private getErrorPreview(): string {
    const message = this.translateService.instant('previewNotAvailableMessage');
    return `<div class="preview-not-available">${message}</div>`;
  }
  private adjustSizes() {
    const infoWrapper = document.querySelector('.additional-information') as HTMLElement;
    if (!infoWrapper) return;
    const column = document.querySelector('.compact-column') as HTMLElement;
    const products = column.querySelector('.compact-products') as HTMLElement;
    const tabsPanel = infoWrapper.querySelector('app-tabs-panel') as HTMLElement;
    const hColumn = column.clientHeight;
    const hProducts = products.clientHeight;
    const hWrapper = hColumn - hProducts - 32;
    const wColumn = column.clientWidth;
    infoWrapper.setAttribute('style', `max-width: ${wColumn - 16}px; max-height: ${hWrapper}px;`);
    const hInfo = infoWrapper.clientHeight;
    const hTabs = tabsPanel.clientHeight;
    const hLinks = hInfo - hTabs;
    this.linksStyle = `max-height: ${hLinks}px;`;
  }
}
