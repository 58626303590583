import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import {ColDef, GridApi, IRowNode} from 'ag-grid-community';
import { CheckboxCellRenderer } from 'src/app/shared/grid/cell-renderers/checkbox.renderer';
import {
  CampaignAction,
  CampaignActionSelection,
  CampaignActionSelectionPortfolio,
  ContentMatchState,
  Portfolio,
  SuitabilityState
} from "../../api/core";

export const COLUMN_ACTION_COMMONS = {
  filter: false,
  sortable: false,
  suppressHeaderMenuButton: true,
  suppressNavigable: true,
  resizable: false,
  autoHeight: true,
  valueGetter: () => '',
};

export interface EntityParams {
  dataBind?: string;
  path?: (any) => string;
  idPath?: (any) => string;
}

export interface CellRendererParams {
  actionsParams?: any;
  entityParams?: EntityParams;
  extraParams?: any;
  suppress?: (data?: any) => boolean; // Hide single cells.
  textFormatter?: (text?: string) => string;
}

export function genHeaderCheckboxColumn(
  gridSelUtils: GridSelectionUtils,
  hasAuthority: boolean = true,
  lvl: number,
  field: string = 'id',
  sortable: boolean = false,
  headerTooltip: string = ''
): ColDef {
  if (!hasAuthority) {
    return null;
  }
  return {
    field,
    headerName:  '',
    headerTooltip,
    maxWidth: 52,
    width: 52,
    minWidth: 52,
    cellRenderer: CheckboxCellRenderer,
    cellRendererParams: {
      actionsParams: {
        gridSelectionUtils: gridSelUtils,
        level: lvl,
      },
    },
    suppressColumnsToolPanel: true,
    lockVisible: true,
    lockPosition: true,
    ...COLUMN_ACTION_COMMONS,
    sortable,
    unSortIcon: sortable,
  };
}

export interface CampaignActionSelectionWithContentMatchState extends CampaignActionSelection {
  contentMatchState?: ContentMatchState;
  hasContentOverride?: boolean;
  combinedSuitabilityState?: SuitabilityState;
  portfolioContainsBuyProducts: boolean;
}

export class GridSelectionUtils {
  private readonly _isSelectable: (data: object) => boolean;
  private readonly _extractInfo: (data: object) => object;
  private readonly _indexField: string;
  private readonly _useAllPortfolioData: boolean;

  private readonly _selectionChangeEmitter = new EventEmitter<
    Map<number, object>[]
  >();

  /**
   * Maps ids of element to element values for all of the selected elements
   */
  private selectedElements: Map<number, object> = new Map<number, object>();

  constructor(
    isRowSelectable: (data: object) => boolean = (_) => true,
    extractInfo: (data: any) => object = (data) => ({ id: data.id }),
    indexField: string = 'id',
    useAllPortfolioData: boolean = false,
  ) {
    this._isSelectable = isRowSelectable;
    this._extractInfo = extractInfo;
    this._indexField = indexField;
    this._useAllPortfolioData = useAllPortfolioData;
  }

  public getSelectionChangeObservable(): Observable<Map<number, object>[]> {
    return this._selectionChangeEmitter.asObservable();
  }

  public getSelectedValues(): Array<any> {
    return Array.from(this.selectedElements.values());
  }

  public setSelection(id: number, info: any, selected: boolean) {
    this.setSelectionImpl(id, info, selected);
    this._selectionChangeEmitter.emit();
  }

  private setSelectionImpl(id: number, info: any, selected: boolean) {
    if (this.isCampaignAction(info)) {
      info = this.convertToSelection(info);
    }
    if (this._isSelectable(info) && selected) {
      this.selectedElements.set(id, this._extractInfo(info));
    } else {
      this.selectedElements.delete(id);
    }
  }

  private isCampaignAction(row: any): row is CampaignAction {
    return typeof row === 'object' && !!row.viewed && typeof row.viewed === 'object';
  }

  private convertToSelection(src: CampaignAction): CampaignActionSelectionWithContentMatchState {
    let portfolio: Portfolio | CampaignActionSelectionPortfolio | undefined = undefined;
    if (this._useAllPortfolioData) {
      portfolio = src.portfolio;
    } else if(src.portfolio) {
      portfolio = {
        id: src.portfolio.id,
        ident: src.portfolio.ident,
        bpName: src.portfolio.bpName,
        number: src.portfolio.number,
        advisor: src.portfolio.advisor,
        relationshipManager: src.portfolio.relationshipManager,
      };
    }
    return {
      id: src.id,
      status: src.status,
      hasCidPermission: src.hasCidPermission,
      language: src.language ? { ...src.language }: undefined,
      channel: src.channel ? { ...src.channel } : null,
      assignee: {
        id: src.assignee.id,
        username: src.assignee.username,
        fullname: src.assignee.fullname,
      },
      sender: {
        id: src.sender.id,
        username: src.sender.username,
        fullname: src.sender.fullname,
      },
      client: src.client ? {
        id: src.client.id,
        ident: src.client.ident,
        fullName: src.client.fullName,
      } : undefined,
      preferredLanguage: { ...src.portfolio.preferredLanguage },
      portfolio,
      contentMatchState: src.contentMatchState,
      hasContentOverride: src.hasContentOverride,
      combinedSuitabilityState: src.combinedSuitabilityState,
      portfolioContainsBuyProducts: src.portfolioContainsBuyProducts
    }
  }

  public setMultipleSelection(items: Array<any>, selected: boolean) {
    items.forEach((i) =>
      this.setSelectionImpl(i[this._indexField], i, selected)
    );
    this._selectionChangeEmitter.emit();
  }

  public isRowInCurrentPage(
    node: IRowNode,
    api: GridApi,
    rowIndex: number
  ): boolean {
    return (
      rowIndex >=
        api.paginationGetPageSize() * api.paginationGetCurrentPage() &&
      rowIndex <
        api.paginationGetPageSize() * (api.paginationGetCurrentPage() + 1)
    );
  }

  public clearSelection() {
    this.selectedElements.clear();
    this._selectionChangeEmitter.emit();
  }

  public isSelectable(data: object): boolean {
    return this._isSelectable(data);
  }

  public isSelected(id: number): boolean {
    return this.selectedElements.has(id);
  }

  public indexField(): string {
    return this._indexField;
  }

  public toggleSelection(id: number, info: object) {
    this.setSelection(id, info, !this.isSelected(id));
  }

  /**
   * Updates the selection of a single row if it is selected.
   * @param id
   * @param action
   */
  updateSelection(id: number, action: CampaignAction) {
    if (this.selectedElements.has(id)) {
      this.setMultipleSelection([action], true);
    }
  }
}
