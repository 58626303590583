<div *ngIf="story" class="basic-flex">
  <ng-container *ngFor="let btn of storyButtons">
    <button
      *ngIf="btn.show && btn.show()"
      mat-flat-button
      [color]="btn.color"
      [matTooltip]="btn.tooltip ? btn.tooltip() : undefined"
      [disabled]="btn.disabled && btn.disabled()"
      (click)="btn.click()"
    >
      <app-config-icon [iconIdentifier]="btn.icon"></app-config-icon>
      <span>{{ btn.text | translate }}</span>
    </button>
  </ng-container>
</div>
