import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {PageBarButton} from "../../../../services/page.bar.service";
import {DataService, SelectionOptions, SelectionStatus} from "../../../../services/data.service";
import {PermissionService} from "../../../../services/permission.service";
import {PortfolioListType} from "../campaign-overview.component";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {
  Campaign,
  CampaignAction,
  CampaignActionStatus,
  CampaignStatus,
  CollectionService,
  ContentMatchState,
  EmployeeCollectionService,
  Portfolio,
  UserInteraction,
  UserService
} from "../../../../api/core";
import {Subscription} from "rxjs";
import {EProtectedActions} from "../../../../util/protected-actions";
import {TranslateService} from "@ngx-translate/core";
import {EModalType, EPortfolioActionStatus} from "../../../../util/enum";
import {ModalData} from "../../../../models/modal.model";
import {CampaignActionProcessingComponent} from "../campaign-action-processing/campaign-action-processing.component";
import {ModalService} from "../../../../services/modal.service";
import {
  ButtonAction,
  DropdownSelectionBarComponent
} from "../../../../shared/grid/custom-header/dropdown-selection-bar/dropdown-selection-bar.component";
import {NotificationService} from "../../../../services/notification.service";
import {ActionType} from "../campaign-actions-list-utils";
import {DatasourceFilter} from "../../../../shared/grid/datasource-filter";
import {GlobalService} from "../../../../services/global.service";
import {ColumnState, GridApi} from "ag-grid-community";
import {
  UserCollectionPickerComponent
} from "../../../../my-settings/dialogs/user-collection-picker/user-collection-picker.component";
import {
  EmployeeCollectionPickerComponent
} from "../../../../my-settings/dialogs/employee-collection-picker/employee-collection-picker.component";
import {GridSelectionUtils} from "../../../../util/grid/grid-selection.util";
import {GridSelectedItemsProvider, GridSelectedItemsProviderByCollection} from "../../../../shared/grid/data-source";
import {first} from "rxjs/operators";

type PortfolioActionRow = {
  action: CampaignAction;
  portfolio: Portfolio;
}

@Component({
  selector: 'app-campaign-action-toolbar',
  templateUrl: './campaign-action-toolbar.component.html'
})
export class CampaignActionToolbarComponent implements OnInit, OnDestroy {
  @ViewChild('selectionDropdown')
  selectionDropdown: DropdownSelectionBarComponent;

  @Input() portfolioListType: PortfolioListType | undefined;
  @Input() actionType: ActionType = ActionType.CampaignAction;
  @Input() pageButtons: PageBarButton[] = [];
  @Input() hideSwitch: boolean = false;

  @Output() portfolioTypeChanged = new EventEmitter<PortfolioListType>();
  @Output() viewSwitched = new EventEmitter<void>();

  primaryButtons: PageBarButton[] = [
    {
      icon: 'delete',
      text: 'delete',
      className: 'icon-text-btn error-btn',
      color: 'warn',
      click: () => this.onDelete(),
      tooltip: () => this.translateService.instant('deleteSelectionFromCampaign'),
      disabled: () => !this.someSelected && !this.allSelected,
      show: () => this.deleteVisible && this.hasDeletePermissions,
    },
    {
      icon: 'send',
      text: 'send',
      className: 'icon-text-btn accent-btn',
      color: 'primary',
      click: () => this.onExecuteActions(),
      tooltip: () =>
        this.someActionsAreExecutable
          ? this.translateService.instant('send')
          : this.translateService.instant('sendNotPossible'),
      disabled: () => !this.someActionsAreExecutable,
      show: () =>
        this.actionsVisible &&
        this.permissionService.hasAnyPermission(
          EProtectedActions.executeActionCampaign
        ),
    },
    {
      icon: 'bulk_edit',
      text: 'bulkEdit',
      className: 'icon-text-btn',
      click: () => this.onShowBulkEdit(),
      disabled: () => !this.bulkSelectedValues.some(
        (action) => action.status === EPortfolioActionStatus.pending
      ),
      show: () =>
        this.actionsVisible &&
        this.permissionService.hasAllPermissions(
          EProtectedActions.editActionLanguage,
          EProtectedActions.editCampaignChannel,
          EProtectedActions.editCampaignSender,
          EProtectedActions.editBulk,
        ),
    },
  ];
  dropdownButtons: PageBarButton[] = [];
  collectionButtons: PageBarButton[] = [];
  campaign: Campaign;
  gridApi: GridApi;
  showDropdownSelectionBar: boolean = false;

  private selectionButtons: PageBarButton[] = [];
  private actionsVisible: boolean = false;
  private deleteVisible: boolean = false;
  private hasDeletePermissions: boolean = false;
  private allSelected: boolean = false;
  private someSelected: boolean = false;
  private someActionsAreExecutable = false;
  private someActionsAreReversible = false;
  private collectionIds: Set<number> = new Set<number>();
  private automaticallyCreatedCollections: PageBarButton[] = [
    {
      text: 'preselectionRules',
      click: () => this.selectAllPagesPreselection(),
      show: () => !!this.selectAllPreselectionProvider,
      privateCollection: false,
    },
  ];
  private createNewCollectionAction: ButtonAction = {
    text: 'collectionsAdd',
    className: 'create-collection-btn',
    click: () => this.createNewCollection(),
    show: () => true,
    privateCollection: false,
  };
  private gridSelectionUtils: GridSelectionUtils;
  private selectAllProvider: GridSelectedItemsProvider;
  private selectAllPreselectionProvider: GridSelectedItemsProvider;
  private selectAllByCollectionPreselectionProvider: GridSelectedItemsProviderByCollection;
  private selectionCleared: () => void = () => {
  };
  private refreshTable: (force: boolean, redrawRows: boolean) => void = () => {
  };
  private selectionProcessing: (loading: boolean) => void = () => {
  };
  private searchText: () => string;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly dataService: DataService,
    private readonly globalService: GlobalService,
    private readonly permissionService: PermissionService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly notificationService: NotificationService,
    private readonly collectionService: CollectionService,
    private readonly employeeCollectionService: EmployeeCollectionService,
    private readonly userService: UserService,
  ) {
  }

  get campaignStatus() {
    return CampaignStatus;
  }

  get bulkSelectedValues(): any[] {
    if (!this.gridSelectionUtils) return [];
    return this.gridSelectionUtils.getSelectedValues()
      .filter((cp: any) => !cp.action.hasContentOverride);
  }

  get options(): SelectionOptions {
    return {
      gridSelectionUtils: this.gridSelectionUtils,
      selectAllProvider: this.selectAllProvider,
      selectAllPreselectionProvider: this.selectAllPreselectionProvider,
      selectAllByCollectionPreselectionProvider: this.selectAllByCollectionPreselectionProvider,
      selectionCleared: this.selectionCleared,
      refreshTable: this.refreshTable,
      selectionProcessing: this.selectionProcessing,
      searchText: this.searchText,
    }
  }

  set options(opt: SelectionOptions) {
    this.gridSelectionUtils = opt.gridSelectionUtils;
    this.selectAllProvider = opt.selectAllProvider;
    this.selectAllPreselectionProvider = opt.selectAllPreselectionProvider;
    this.selectAllByCollectionPreselectionProvider = opt.selectAllByCollectionPreselectionProvider;
    this.selectionCleared = opt.selectionCleared;
    this.refreshTable = opt.refreshTable;
    this.selectionProcessing = opt.selectionProcessing;
    this.searchText = opt.searchText;
    this.subscriptions.push(
      this.gridSelectionUtils.getSelectionChangeObservable().subscribe(() => this.onGridSelectionChanged())
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.dataService.campaign$.subscribe((c => this.onCampaignChanged(c))),
      this.permissionService.user$.subscribe(() => this.updateSelectionHiddenActions()),
    );
    this.setSelectionStatus({});
    this.selectionButtons = this.generateSelectionActions();
    this.dropdownButtons = [
      ...this.pageButtons,
      ...this.selectionButtons,
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onPortfolioListTypeChanged(ev: MatButtonToggleChange) {
    this.portfolioTypeChanged.next(this.portfolioListType);
  }

  private onDelete() {
    const selectedRows = this.getSelectedActionIdsRows();
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.removeActionsDialog, this.campaign, selectedRows);
  }

  private onExecuteActions() {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === EPortfolioActionStatus.pending && row.action.contentMatchState !== ContentMatchState.MISSING
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.executeActionsDialog, this.campaign, selectedRows);
  }

  private onShowBulkEdit() {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === CampaignActionStatus.PENDING
        && !row.action.hasContentOverride
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.bulkEdit, this.campaign, selectedRows);
  }

  private onCampaignChanged(campaign: Campaign) {
    this.campaign = campaign;
    this.actionsVisible = campaign.status == CampaignStatus.LAUNCHED;
    this.deleteVisible = campaign.status == CampaignStatus.FROZEN;
    this.showDropdownSelectionBar = [CampaignStatus.LAUNCHED, CampaignStatus.FROZEN].includes(campaign.status);
    if (this.campaign.decentralized) {
      this.hasDeletePermissions = this.permissionService.hasAnyPermission(
        EProtectedActions.decentralizedCampaignEdit
      )
    } else {
      this.hasDeletePermissions = this.permissionService.hasAnyPermission(
        EProtectedActions.deletePortfolioClientCampaign
      )
    }
    this.collectionButtons = this.actionType == ActionType.CampaignAction
      ? [...this.automaticallyCreatedCollections, this.createNewCollectionAction]
      : [this.createNewCollectionAction];
  }

  private onGridSelectionChanged() {
    this.dropdownButtons = [
      ...this.pageButtons,
      ...this.selectionButtons,
    ];
    this.updateSelectionStatus();
  }

  private setSelectionStatus(status: Partial<SelectionStatus>) {
    this.dataService.setSelectionStatus({
      count: this.gridSelectionUtils?.getSelectedValues()?.length ?? 0,
      all: this.allSelected,
      some: this.someSelected,
      ...status
    })
  }

  selectAllClicked(selected: boolean) {
    this.clearSelection();
    this.selectAllPages(selected);
  }

  private getSelectedActionIdsRows(
    predicate: (row: PortfolioActionRow) => boolean = () => true
  ): PortfolioActionRow[] {
    if (!this.gridSelectionUtils) return [];
    const selectedActions = this.gridSelectionUtils.getSelectedValues();
    return selectedActions.filter(predicate);
  }

  private prepareDataAndShowModal(modalType: EModalType, campaign: Campaign, selectedRows: PortfolioActionRow[]) {
    const modalData: ModalData = {
      title: null,
      type: modalType,
      data: {modalType, campaign, selectedRows, isHierarchyList: this.portfolioListType === 'hierarchy'},
      component: CampaignActionProcessingComponent,
    };
    this.openModal(modalData);
  }

  private openModal(modalData: ModalData) {
    const modalType = modalData.type;
    const data = {
      ...modalData,
      submitBtn: {label: ''},
      cancelBtn: {label: this.translateService.instant('cancel')},
    };
    const dialogRef = this.modalService.openDefaultDialog(
      data,
      undefined,
      undefined,
      true
    );
    dialogRef.afterClosed().subscribe((result) => {
      // clear selection if modal was closed with submit, keeping the selection afterward if it's a bulk-edit
      if (result) {
        // if flat-list and custom content or sender columns are affected redraw rows as well
        if (this.actionType === ActionType.CampaignAction && (
          modalType === EModalType.executeActionsDialog ||
          modalType === EModalType.setNoActionsDialog ||
          modalType === EModalType.revertActionsDialog
        )) {
          this.refresh(true);
        } else {
          this.refresh();
        }
        const keepSelectionTypes = [
          EModalType.bulkEdit
        ];
        if (keepSelectionTypes.includes(modalType) && result.updatedActions) {
          // a timeout is required since the refresh operation is asynchronous
          setTimeout(
            () => this.gridSelectionUtils.setMultipleSelection(result.updatedActions, true),
            250
          );
        }
        if (modalType === EModalType.bulkEdit) {
          this.userService.recordUserInteraction(
             this.actionType === ActionType.CampaignAction ?
               UserInteraction.BULKEDITEDCAMPAIGNACTION : UserInteraction.BULKEDITEDCAMPAIGNINTERMEDIARYACTION
          ).pipe(first()).subscribe();
        }
      }
    });
  }

  clearSelection() {
    this.gridSelectionUtils.clearSelection();
    this.selectionDropdown?.clear();
    this.selectionCleared();
  }

  private refresh(redrawRows: boolean = false): void {
    this.clearSelection();
    this.refreshTable && this.refreshTable(true, redrawRows);
  }

  private selectAllPagesPreselection() {
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectionProcessing(true);
    this.gridSelectionUtils.clearSelection();
    this.selectAllPreselectionProvider(filter.toString()).subscribe((items) => {
      this.gridSelectionUtils.setMultipleSelection(items, true);
      this.sortBySelected();
      this.gridApi.refreshServerSide({purge: true}); // force server side refresh, to apply sorting
      this.selectionProcessing(false);
    });
  }

  private generateSelectionActions(): ButtonAction[] {
    const isCampaignAction = this.actionType === ActionType.CampaignAction;
    return [
      {
        icon: 'cancel_schedule_send',
        text: 'noAction',
        className: 'icon-text-btn',
        click: () =>
          this.someActionsAreExecutable && this.onNoActions(),
        tooltip: () =>
          this.someActionsAreExecutable
            ? this.translateService.instant('setNoAction')
            : this.translateService.instant('setNoActionNotPossible'),
        disabled: () => !this.someActionsAreExecutable,
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.setNoActionCampaign
          ),
      },
      {
        icon: 'restart_alt',
        text: 'revertAction',
        className: 'icon-text-btn',
        click: () =>
          this.someActionsAreReversible && this.onRevertActions(),
        tooltip: () =>
          this.someActionsAreReversible
            ? this.translateService.instant('revertAction')
            : this.translateService.instant('revertActionNotPossible'),
        disabled: () => !this.someActionsAreReversible,
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.revertActionToPendingCampaign
          ),
      },
      isCampaignAction ? {
        icon: 'person_add',
        text: 'assignToAdvisor',
        className: 'icon-text-btn',
        click: () =>
          this.isAssignableForSomeSelectedElems('advisor') &&
          this.onAssignToAdvisor(),
        tooltip: () => this.translateService.instant('assignToAdvisor'),
        disabled: () => !this.isAssignableForSomeSelectedElems('advisor'),
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.assignToAdvisorCampaign
          ),
      } : undefined,
      isCampaignAction ? {
        icon: 'supervised_user_circle',
        text: 'assignToRelationshipManager',
        className: 'icon-text-btn',
        click: () =>
          this.isAssignableForSomeSelectedElems('relationshipManager') &&
          this.onAssignToRelationshipManager(),
        tooltip: () => this.translateService.instant('assignToRelationshipManager'),
        disabled: () =>
          !this.isAssignableForSomeSelectedElems('relationshipManager'),
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.assignToRmCampaign
          ),
      } : undefined,
      isCampaignAction ? {
        icon: 'sync',
        text: 'suitability',
        className: 'icon-text-btn',
        click: () => this.refreshSuitability(),
        tooltip: () => this.translateService.instant('updateSuitability'),
        show: () =>
          this.permissionService.hasAnyPermission(
            EProtectedActions.refreshSuitabilityCampaign
          ),
      } : undefined,
    ].filter(a => a);
  }

  private onNoActions(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === EPortfolioActionStatus.pending
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.setNoActionsDialog, this.campaign, selectedRows);
  }

  private onRevertActions(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status !== EPortfolioActionStatus.pending
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.revertActionsDialog, this.campaign, selectedRows);
  }

  private isAssignableForSomeSelectedElems(type: string): boolean {
    const assignableValues = this.getAssignableActions(type);
    return assignableValues.length > 0;
  }

  private getAssignableActions(type: string): number[] {
    const filter =
      type === 'advisor'
        ? (row) => {
          return row.action.status === EPortfolioActionStatus.pending &&
            (!row.portfolio ||
              (row.portfolio.advisor && row.portfolio.advisor.id !== row.action.assignee?.id)
            )
        }
        : (row) => {
          return row.action.status === EPortfolioActionStatus.pending &&
            (!row.portfolio ||
              (row.portfolio.relationshipManager &&
                row.portfolio.relationshipManager.id !== row.action.assignee?.id)
            );
        }
    return this.getSelectedActionIdsRows(filter).map((row) => row.action.id);
  }

  private onAssignToAdvisor(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) =>
        row.action.status === EPortfolioActionStatus.pending &&
        row.portfolio.advisor &&
        row.portfolio.advisor.id !== row.action?.assignee?.id
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.assignActionsToAdvisorDialog, this.campaign, selectedRows);
  }

  private onAssignToRelationshipManager(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) =>
        row.action.status === EPortfolioActionStatus.pending &&
        row.portfolio.relationshipManager &&
        row.portfolio.relationshipManager?.id !== row.action.assignee?.id
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.assignActionsToRelationshipManagerDialog, this.campaign, selectedRows);
  }

  private refreshSuitability(): void {
    const selectedRows = this.getSelectedActionIdsRows();
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.refreshSuitabilitiesDialog, this.campaign, selectedRows);
  }

  private sortBySelected() {
    if (!this.gridApi) return;
    const colStates: ColumnState[] = (this.gridApi.getColumnState() || [])
      .map(colState => ({
        ...colState,
        sort: colState.colId === 'id' ? 'desc' : null
      }));
    this.gridApi.applyColumnState({
      state: colStates
    });
  }

  private createNewCollection() {
    const modalData: ModalData = {
      type: EModalType.pickClients,
      title: 'collectionsAdd',
      data: {
        name: '',
        isPublic: false,
        selected: [],
      },
      component: this.actionType == ActionType.CampaignAction ?
        UserCollectionPickerComponent : EmployeeCollectionPickerComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      'pick-client-dialog'
    );
    dialogRef.afterClosed().subscribe((result: number[]) => {
      if (result) {
        this.updateSelectionHiddenActions().then(() => {
          this.selectionDropdown.selectedAction = this.collectionButtons.find((action) =>
            action.collectionId && action.collectionId === result[0]
          );
        });
      }
    });
  }

  private updateSelectionHiddenActions(): Promise<void> {
    return new Promise((resolve) => {
      (
        this.actionType == ActionType.CampaignAction
          ? this.collectionService.getUserAndDeputyCollections()
          : this.employeeCollectionService.getUserAndDeputyCollections()
      ).subscribe((collections) => {
        const newCollections = collections.filter((c) =>
          !this.collectionIds.has(c.id)
        );
        this.collectionIds = new Set<number>(collections.map((c) => c.id));
        const newActions = newCollections.map(
          (c) =>
            ({
              text: c.isOwner ? c.name : `${c.name} (${c.user.fullname})`,
              click: () => this.selectCollection(c.id),
              disabled: () => false,
              show: () => true,
              collectionId: c.id,
              privateCollection: c.isOwner,
            } as ButtonAction)
        );
        this.collectionButtons.push(...newActions);
        this.collectionButtons = this.collectionButtons.filter((action) =>
          this.collectionIds.has(action.collectionId) || action.collectionId === undefined
        );
        // asynchronous operations need collectionButtons to be updated before resolving
        resolve();
        // clear selection if previously selected collection is not in list anymore
        if (
          this.selectionDropdown &&
          this.selectionDropdown._selectedAction?.collectionId &&
          !this.collectionIds.has(this.selectionDropdown._selectedAction.collectionId)
        ) {
          this.clearSelection();
        }
      });
    });
  }

  private selectCollection(collectionId: number) {
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectionProcessing(true);
    this.gridSelectionUtils.clearSelection();
    this.selectAllByCollectionPreselectionProvider(collectionId, filter.toString())
      .subscribe((items) => {
        this.gridSelectionUtils.setMultipleSelection(items, true);
        this.sortBySelected();
        this.gridApi.refreshServerSide({purge: true}); // force server side refresh, to apply sorting
        this.selectionProcessing(false);
      });
  }

  private updateSelectionStatus() {
    this.allSelected = this._allSelected();
    this.someSelected = this._someSelected();
    this.someActionsAreExecutable = this.actionIsExecutableForSomeSelectedElems();
    this.someActionsAreReversible = this.actionIsReversibleForSomeSelectedElems();
    this.setSelectionStatus({});
  }

  private _allSelected(): boolean {
    // for single-level grids we can use the selected-available numbers
    if (!this.gridApi.getGridOption('detailCellRendererParams')) {
      return this.gridSelectionUtils.getSelectedValues().length === this.gridApi.paginationGetRowCount();
    }
    // TODO: this only considers current page, not all pages https://github.com/confinale/aspark/issues/9100
    const rowsOnPage = this.getRowsOnPage();
    const nodesOnPage = this.getDetailRowsOfMasterRows(rowsOnPage).filter((k) =>
      this.gridSelectionUtils.isSelectable(k)
    );

    if (nodesOnPage.length == 0 && rowsOnPage.length > 0) {
      return rowsOnPage.every((k) =>
        this.gridSelectionUtils.isSelected(
          k[this.gridSelectionUtils.indexField()]
        )
      );
    } else {
      return (
        nodesOnPage.length > 0 &&
        nodesOnPage.every((k) =>
          this.gridSelectionUtils.isSelected(
            k[this.gridSelectionUtils.indexField()]
          )
        )
      );
    }
  }

  private _someSelected(): boolean {
    return (
      this.gridSelectionUtils.getSelectedValues().length > 0 &&
      !this._allSelected()
    );
  }

  private getRowsOnPage(): Array<any> {
    const nodes = new Array<any>();
    if (this.gridApi) {
      this.gridApi.forEachNode((rowNode, index) => {
        if (
          rowNode.data &&
          this.gridSelectionUtils.isRowInCurrentPage(
            rowNode,
            this.gridApi,
            index
          )
        ) {
          nodes.push(rowNode.data);
        }
      });
    }
    return nodes;
  }

  private actionIsExecutableForSomeSelectedElems(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues();
    return (
      this.permissionService.hasAnyPermission(
        EProtectedActions.executeActionCampaign
      ) &&
      selValues.some(
        (d) => d.status === EPortfolioActionStatus.pending && d.action.contentMatchState !== ContentMatchState.MISSING // Hide if no action is pending
      )
    );
  }

  private actionIsReversibleForSomeSelectedElems(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues();

    return (
      this.permissionService.hasAnyPermission(
        EProtectedActions.executeActionCampaign
      ) &&
      !selValues.every(
        (action) => action.status === EPortfolioActionStatus.pending // Hide if all actions are pending
      )
    );
  }

  private getDetailRowsOfMasterRows(masterRows: any[]): any[] {
    const subRows = [];
    masterRows.forEach((n) => {
      if (n.actions) {
        n.actions.forEach((a) => subRows.push(a));
      }
    });
    return subRows;
  }

  private selectAllPages(selected: boolean = true) {
    // if it's a de-selection, there's no need to call any API, just clear the selection and return
    if (selected === false) {
      this.gridApi.hideOverlay();
      return;
    }
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectAllProvider(filter.toString(), this.searchText()).subscribe((items) => {
      this.gridSelectionUtils.setMultipleSelection(items, selected);
      this.selectionProcessing(false);
    });
  }

  protected readonly ActionType = ActionType;
}
