<form [formGroup]="campaignForm" class="modal-form">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{ 'campaignName' | translate }}</mat-label>
      <input matInput formControlName="name" type="text" />
      <mat-error *ngIf="!campaignForm.controls.name.valid">{{
        'inputTooLong'
          | translate: { maxLength: formValidators.textFieldMaxLength }
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="row row-double">
    <mat-form-field>
      <mat-label>{{ 'useCase' | translate }}</mat-label>
      <mat-select formControlName="useCase" (selectionChange)="updateCodeTables()">
        <mat-option *ngFor="let useCase of useCases" [value]="useCase">
          {{ useCase.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'publicationType' | translate }}</mat-label>
      <mat-select formControlName="publicationType" (selectionChange)="updateCodeTables()">
        <mat-option *ngFor="let publicationType of publicationTypes" [value]="publicationType">
          {{ publicationType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="canEditHub">
      <mat-label>{{ 'hub' | translate }}</mat-label>
      <mat-select formControlName="hub" (selectionChange)="updateCodeTables()">
        <mat-option *ngFor="let hub of hubs" [value]="hub">
          {{ hub.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'validFrom' | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="validFromDatePicker"
        formControlName="validFrom"
        [min]="minDate"
        [max]="campaignForm.controls.validTo.value"
        (click)="validFromDatePicker.open()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="validFromDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #validFromDatePicker></mat-datepicker>
      <mat-error *ngIf="!campaignForm.controls.validFrom.valid">{{
        'dateNotValid' | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'validTo' | translate }}</mat-label>
      <input
        matInput
        [min]="campaignForm.controls.validFrom.value"
        [max]="maxDate"
        [matDatepicker]="validToDatePicker"
        (click)="validToDatePicker.open()"
        formControlName="validTo"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="validToDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #validToDatePicker></mat-datepicker>
      <mat-error *ngIf="!campaignForm.controls.validTo.valid">{{
        'dateNotValid' | translate
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="row row-double" id="split-panel">
    <mat-form-field>
      <mat-label>{{ 'storyInfo' | translate }}</mat-label>
      <textarea matInput formControlName="info" type="text" [readonly]="!canEditInfo"></textarea>
    </mat-form-field>

    <app-external-links
      [externalLinks]="externalLinks"
      (externalLinksChanged)="handleExternalLinksChanged($event)"
    ></app-external-links>
  </div>
</form>
