import {TranslateService} from '@ngx-translate/core';
import {
  ClientService,
  CollectionEntry,
  CollectionService,
  GridFilterOptionsParams,
  ListParams,
  PortfolioService,
  UserInfo
} from '../../../api/core';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridDataProvider} from '../../../shared/grid/data-source';
import {
  genCodeTableColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel
} from '../../../util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {
  ColDef,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowSelectedEvent
} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {genIconButtonColumn} from '../../../shared/grid/cell-renderers/icon-button.renderer';
import {PermissionService} from "../../../services/permission.service";
import {EProtectedActions} from "../../../util/protected-actions";
import {ECodeTables} from "../../../util/enum";
import {CodeTableService} from "../../../services/code-table.service";
import {MatCheckbox} from "@angular/material/checkbox";
import {ToastrService} from "ngx-toastr";
import {
  MAX_COLLECTION_SIZE,
  MAX_SELECTION_SIZE
} from "../employee-collection-picker/employee-collection-picker.component";
import {NotificationService} from "../../../services/notification.service";

export interface UserCollectionData {
  collectionId: number;
  name: string;
  isPublic: boolean;
  selected: CollectionEntry[];
}

@Component({
  selector: 'app-user-collection-picker',
  templateUrl: './user-collection-picker.component.html',
})
export class UserCollectionPickerComponent implements OnInit, OnDestroy {
  @ViewChild('selectAllCheckbox')
  selectAllCheckbox: MatCheckbox;

  collectionId: number;
  collectionName = '';
  isPublic = false;
  selected: CollectionEntry[] = [];
  selectedApi: GridApi<CollectionEntry>;
  availableGridApi: GridApi<CollectionEntry>;

  selectedColDefs: ColDef[] = [];
  clientsColDef: ColDef[] = [];

  selectedGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => {
      this.gridReadySelected(event);
    },
  };

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
    },
    getRowId: (params: GetRowIdParams<CollectionEntry>) => `${params.data.clientId}-${params.data.portfolioId}`,
    onRowSelected: (event: RowSelectedEvent) => {
      this.setMultipleSelection([event.data], event.node.isSelected());
    },
    onModelUpdated: (_: ModelUpdatedEvent) => {
      if (this.availableGridApi && this.selectedElements?.size > 0) {
        this.updateSelectionOnGrid();
      }
    },
  };

  lastListParams: ListParams;
  gridData: GridDataProvider;
  dataProvider(list: ListParams) {
    this.lastListParams = list;
    return this.collectionService.getAvailableCollectionEntries(list);
  }

  relationshipManagers: UserInfo[] = [];
  advisors: UserInfo[] = [];
  selectedElements: Map<string, CollectionEntry> = new Map<string, CollectionEntry>();
  selectionProcessing = false;

  subscriptions: Subscription[] = [];

  constructor(
    readonly translateService: TranslateService,
    private portfolioService: PortfolioService,
    private permissionService: PermissionService,
    private collectionService: CollectionService,
    private codeTableService: CodeTableService,
    private clientService: ClientService,
    private notificationService: NotificationService,
    private toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: UserCollectionData },
    public dialogRef: MatDialogRef<any>,
  ) {
    this.collectionId = data.data.collectionId;
    this.collectionName = data.data.name;
    this.isPublic = data.data.isPublic;
    this.selected = data.data.selected;
    this.selectedColDefs = this.generateSelectedColDefs();
    this.clientsColDef = this.generateColDefs();
  }

  ngOnInit() {
    this.gridData = this.dataProvider.bind(this);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  gridReady(event: GridReadyEvent) {
    this.availableGridApi = event.api;
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
  }

  gridReadySelected(event: GridReadyEvent) {
    this.selectedApi = event.api;
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    // load selected clients if collectionId is set
    if (this.collectionId) {
      this.selectedApi.showLoadingOverlay();
      this.collectionService
        .getCollectionEntries(this.collectionId)
        .subscribe((entries) => {
          this.selected = entries;
          this.selectedApi.hideOverlay();
        });
    }
  }

  private setMultipleSelection(data: CollectionEntry[], selected: boolean) {
    data.forEach(d => {
      let id = `${d.clientId}-${d.portfolioId}`;
      if (selected) {
        this.selectedElements.set(id, d);
      } else {
        this.selectedElements.delete(id);
      }
    });
  }

  someSelected(): boolean {
    return (
      this.selectedElements.size > 0 &&
      !this.allSelected()
    );
  }

  allSelected(): boolean {
    return (
      this.selectedElements.size > 0 &&
      this.selectedElements.size == this.availableGridApi?.paginationGetRowCount()
    );
  }

  /**
   * If any items are selected: deselect all
   * Else: select all entries from current list
   */
  togglePageSelection(_: MouseEvent) {
    this.selectionProcessing = true;
    this.availableGridApi.showLoadingOverlay();
    this.selectAllCheckbox.checked = false; // If not set, checkbox will be checked after adding all rows
    this.selectAllPages(this.selectedElements.size == 0);
  }

  /**
   * If no items are selected:
   *  Retrieves all entries from the current list, considering the current filter and sort settings [this.lastListParams]
   *  Will only retrieve and select up to maxSelectionSize entries
   * Else:
   *  Deselects all entries
   */
  private selectAllPages(selected: boolean = true) {
    this.clearSelection();
    if (selected) {
      this.collectionService.getCollectionEntriesSelection(this.lastListParams).subscribe((page) => {
        this.setMultipleSelection(page.pageItems, selected);
        this.updateSelectionOnGrid();
        this.selectionProcessing = false;
        this.availableGridApi.hideOverlay();
        if (page.count > MAX_SELECTION_SIZE) {
          this.showSelectionOverflowMessage();
        }
      });
    } else {
      this.selectionProcessing = false;
      this.availableGridApi.hideOverlay();
    }
  }

  clearSelection() {
    this.availableGridApi.deselectAll();
    this.selectedElements.clear();
  }

  updateSelectionOnGrid() {
    this.availableGridApi.forEachNode((node) => {
      if (!node.isSelected() && this.selectedElements.has(node.id)) {
        node.setSelected(true);
      }
    });
  }

  canAdd(): boolean {
    return this.someSelected() || this.allSelected();
  }

  /**
   * Adds selected entries, that are not already in the collection, to the collection
   * If the collection exceeds maxCollectionSize, only as many entries as possible are added
   */
  addSelection() {
    if (!this.availableGridApi) {
      return;
    }
    const selection = Array.from(this.selectedElements.values());
    const toAdd = selection
      .filter(c => !this.selected.find(sc => sc.clientId === c.clientId && sc.portfolioId === c.portfolioId));
    const overflow = this.selected.length + toAdd.length - MAX_COLLECTION_SIZE;
    if (overflow > 0) {
      if (overflow < toAdd.length) {
        this.selected = [...this.selected, ...toAdd.slice(0, toAdd.length - overflow)];
      }
      this.showCollectionOverflowMessage(toAdd.length, overflow);
    } else {
      this.selected = [...this.selected, ...toAdd];
      if (toAdd.length < selection.length) {
        this.showCollectionDuplicatesMessage(selection.length, toAdd.length);
      }
    }
    this.clearSelection();
  }

  canSubmit(): boolean {
    return !!this.collectionName && !!this.selected.length && this.selected.length <= MAX_COLLECTION_SIZE;
  }

  onSubmit() {
    const setProcessing = (processing: boolean) => {
      this.selectionProcessing = processing;
      this.selectedApi.showLoadingOverlay();
      this.availableGridApi.showLoadingOverlay()
    }
    setProcessing(true);
    const data = {
      name: this.collectionName,
      isPublic: this.isPublic,
      entries: this.selected,
    };
    const apiCall = this.collectionId ?
      this.collectionService.saveCollection(this.collectionId, data) : this.collectionService.createCollection(data);
    apiCall.subscribe({
      next: (data) => {
        this.notificationService.handleSuccess(
          this.translateService.instant('collectionsSavedMessage')
        );
        this.dialogRef.close([data?.id]);
      },
      complete: () => {
        setProcessing(false);
      }
    });
  }

  private deleteEntry(entry: CollectionEntry) {
    this.selected = this.selected
        .filter(c => !(c.clientId === entry.clientId && c.portfolioId === entry.portfolioId));
  }

  selectAllTooltip() {
    return this.selectedElements?.size > 0 ?
      this.translateService.instant('deselectAll') : this.translateService.instant('selectAllPages');
  }

  private showSelectionOverflowMessage() {
    this.toastService.info(
      this.translateService.instant('selectAllOverflowMessage')
        .replace(/MAX_SELECTED/g, MAX_SELECTION_SIZE.toString()),
      this.translateService.instant('selectAllOverflowTitle')
    );
  }

  private showCollectionOverflowMessage(toAddLength: number, overflow: number) {
    this.toastService.info(
      this.translateService.instant('collectionOverflowMessage',
        {
          maxSize: MAX_COLLECTION_SIZE.toString(),
          added: Math.max(toAddLength - overflow, 0).toString()
        }
      ),
      this.translateService.instant('collectionOverflowTitle')
    );
  }

  private showCollectionDuplicatesMessage(selected: number, toAddLength: number) {
    this.toastService.info(
      this.translateService.instant('collectionDuplicatesMessage',
        {
          duplicates: (selected - toAddLength).toString(),
          added: toAddLength.toString()
        }
      ),
      this.translateService.instant('collectionDuplicatesTitle')
    );
  }

  private fetchSelectedRelationshipManagers(params: any) {
    params.success(this.selected.map((d) => d.relationshipManager.username));
  }

  private fetchSelectedAdvisors(params: any) {
    params.success(this.selected.map((d) => d.advisor?.username).filter((d) => !!d));
  }

  private fetchRelationshipManagers(params: any) {
    this.portfolioService
        .getPortfolioRelationshipManagers()
        .subscribe((data) => {
          this.relationshipManagers = data;
          params.success(data.map((d) => d.username));
        });
  }

  private fetchAdvisors(params: any) {
    this.portfolioService
        .getPortfolioAdvisors()
        .subscribe((data) => {
          this.advisors = data;
          params.success(data.map((d) => d.username));
        });
  }

  private generateSelectedColDefs(): ColDef[] {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    return [
      {
        ...genIconButtonColumn({
          callback: (data: CollectionEntry) => this.deleteEntry(data),
          icon: 'delete',
          tooltip: this.translateService.instant('removeClient'),
        }),
        floatingFilter: false,
        colId: 'icon-button-delete',
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'clientPersonNumber',
          headerName: I18n.getColName('personNumber'),
          autoCompleteParams: () => this.selected.map(d => d.clientPersonNumber)
        }),
        floatingFilter: true,
        sortable: true,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'clientFullName',
          headerName: I18n.getColName('fullName'),
          autoCompleteParams: () => this.selected.map(d => d.clientFullName)
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'portfolioNumber',
          headerName: I18n.getColName('portfolioNumber'),
          autoCompleteParams: () => this.selected.map(d => d.portfolioNumber),
        }),
      },
      {
        ...genTextColumn('portfolioBpName', I18n.getColName('bpName')),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
        hide: true,
      },
      {
        ...genCodeTableColumn({
          field: 'clientRoleName',
          headerName: I18n.getColName('clientRole'),
          observable: this.codeTableService.getCodeTable(ECodeTables.clientRole),
          hasPrimitiveCellValues: true,
        }),
        hide: true,
      },
      {
        ...genUserEnumColumn(
          'relationshipManager.username',
          I18n.getColName('relationshipManager'),
          this.fetchSelectedRelationshipManagers.bind(this),
          () => this.selected.map((d) => d.relationshipManager),
          null,
          true,
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.relationshipManager),
        hide: true,
      },
      {
        ...genUserEnumColumn(
          'advisor.username',
          I18n.getColName('advisor'),
          this.fetchSelectedAdvisors.bind(this),
          () => this.selected.map((d) => d.advisor),
          null,
          true,
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.advisor),
        hide: true,
      },
      {
        ...genTextColumn(
          'preferredContactChannel',
          I18n.getColName('preferredChannel'),
          null,
          {
            customPath: 'preferredContactChannel.address',
          }
        ),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
        hide: true,
      },
    ];
  }

  private generateColDefs(): ColDef[] {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    return [
      {
        checkboxSelection: true,
        floatingFilter: false,
        sortable: false,
        lockPosition: 'left',
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        suppressHeaderMenuButton: true,
        resizable: true,
      },
      {
        ...genTextColumnWithAutoCompleteFilter( {
          field: 'clientPersonNumber',
          headerName: I18n.getColName('personNumber'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.clientService.getGridFilterOptions(data),
            autoCompleteField: 'personNumber',
          },
          isMultiSelect: true,
          filterParamsInfo: { customPath: 'client.personNumber' },
        }),
        floatingFilter: true,
        sortable: true,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'clientFullName',
          headerName: I18n.getColName('fullName'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.clientService.getGridFilterOptions(data),
            autoCompleteField: 'fullName',
          },
          isMultiSelect: true,
          filterParamsInfo: { customPath: 'client.fullName' },
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'portfolioNumber',
          headerName: I18n.getColName('portfolioNumber'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
            autoCompleteField: 'number',
          },
          isMultiSelect: true,
          filterParamsInfo: { customPath: 'portfolio.number' },
        }),
      },
      {
        ...genTextColumn(
          'portfolioBpName',
          I18n.getColName('bpName'),
          null,
          {
            customPath: 'portfolio.bpName',
          }
        ),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      genCodeTableColumn({
        field: 'role',
        dtoField: 'clientRoleName',
        headerName: this.translateService.instant('clientRole'),
        observable: this.codeTableService.getCodeTable(ECodeTables.clientRole),
      }),
      {
        ...genUserEnumColumn(
          'relationshipManager.username',
          I18n.getColName('relationshipManager'),
          this.fetchRelationshipManagers.bind(this),
          () => this.relationshipManagers,
          'portfolio.relationshipManager.username'
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.relationshipManager),
      },
      {
        ...genUserEnumColumn(
          'advisor.username',
          I18n.getColName('advisor'),
          this.fetchAdvisors.bind(this),
          () => this.advisors,
          'portfolio.advisor.username'
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.advisor),
      },
      {
        ...genTextColumn(
          'preferredContactChannel',
          I18n.getColName('preferredChannel'),
          null,
          {
            customPath: 'preferredContactChannel.address',
          }
        ),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
    ];
  }
}
