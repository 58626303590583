import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DatePipe} from '@angular/common';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, finalize, first, switchMap,} from 'rxjs/operators';
import {
  CodeTableEntry,
  ExternalLink,
  Story,
  StoryCreate,
  StoryService,
  Tag,
  TagService,
  User,
  UserService,
} from 'src/app/api/core';
import {ModalData, ModalSubComponent} from 'src/app/models/modal.model';
import {codeTableEntries, CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {MAX_DATE, MIN_DATE} from 'src/app/util/date-formatter';
import {ECodeTables, EFormStatus, EFormValidators, EModalType, EViewRoutes,} from 'src/app/util/enum';
import {PermissionService} from '../../../services/permission.service';
import {EProtectedActions} from '../../../util/protected-actions';
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ModalService} from "../../../services/modal.service";

/**
 * Component to show the story details (can handle create and edit).
 */
@Component({
  selector: 'app-details-form',
  templateUrl: './story-details-form.component.html',
})
export class StoryDetailsFormComponent
  implements OnInit, OnDestroy, ModalSubComponent {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  subscriptions: Subscription[] = [];
  story: Story;
  storyForm = this.fb.group({
    name: [
      '',
      [
        Validators.required,
        Validators.maxLength(EFormValidators.textFieldMaxLength),
        Validators.pattern(EFormValidators.textFieldNotBlankPattern),
      ],
    ],
    useCase: [null as CodeTableEntry],
    publicationType: [
      null as CodeTableEntry,
      [Validators.required]
    ],
    hub: [
      null as CodeTableEntry,
      [Validators.required]
    ],
    author: [null as User],
    validFrom: [''],
    validTo: [''],
    info: '',
    tags: this.fb.array([] as Tag[], this.tagsRequired),
    hot: [false, Validators.required],
    once: [false, Validators.required],
    externalLinks: [[]],
  }, {
    validators: this.externalLinksValidator.bind(this)
  });

  useCases: CodeTableEntry[] = [];
  publicationTypes: CodeTableEntry[] = [];
  hubs: CodeTableEntry[] = [];
  externalLinks: ExternalLink[] = [];

  authorControl = new FormControl('');
  filteredAuthors$: Observable<User[]>;
  tagControl = new FormControl();
  filteredTags$: Observable<Tag[]>;

  actionButtonLabel = 'Create';
  minDate = MIN_DATE;
  maxDate = MAX_DATE;
  canEditInfo = false;
  canEditHub = false;

  constructor(
    private fb: FormBuilder,
    protected storyService: StoryService,
    protected tagService: TagService,
    protected userService: UserService,
    protected globalService: GlobalService,
    protected dataService: DataService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected datePipe: DatePipe,
    protected dialogRef: MatDialogRef<ModalComponent>,
    protected permissionService: PermissionService,
    protected modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: { data: { story: Story } }
  ) {
    this.handleStory(data.data?.story);
    this.initCodeTables();
    this.canEditInfo = this.permissionService.hasAnyPermission(
      EProtectedActions.editStoryInfo
    );
    this.canEditHub = (data.data?.story?.campaigns?.length ?? 0) == 0 && this.hubs.length > 1;
  }

  ngOnInit(): void {
    // Initial check if form is valid
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled =
      !this.storyForm.controls.name.valid
      || !this.storyForm.controls.publicationType.valid
      || !this.storyForm.controls.hub.valid;

    // Mark publicationType as touched to show required error
    this.storyForm.controls.publicationType.markAsTouched({onlySelf: true});

    this.filteredAuthors$ = this.authorControl.valueChanges.pipe(
      filter((val: string) => val && val.length > 0),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => this.userService.searchUsers(val))
    );
    this.subscriptions.push(
      this.authorControl.valueChanges.subscribe(() => {
        if (this.storyForm.value.author != null) {
          this.storyForm.patchValue({author: null});
        }
      })
    );
    this.filteredTags$ = this.tagControl.valueChanges.pipe(
      filter((val: string) => val && val.length > 0),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => this.tagService.searchTags(val))
    );
    this.subscriptions.push(
      this.storyForm.statusChanges.subscribe(
        (status) =>
          (this.dialogRef.componentInstance.toolbarActionData.btnDisabled =
            status === EFormStatus.INVALID)
      )
    );
    this.subscriptions.push(
      this.storyForm.controls.validFrom.valueChanges.subscribe((value) => {
        const isInvalid = !this.storyForm.controls.validFrom.valid;
        const parseErrorText: string =
          this.storyForm.controls.validFrom.errors?.matDatepickerParse?.text;
        if (value === null && isInvalid && parseErrorText === '') {
          // remove matDatepickerParse error for empty string since field is not required
          this.storyForm.controls.validFrom.reset(null, {
            emitEvent: false,
          });
        }
      })
    );
    this.subscriptions.push(
      this.storyForm.controls.validTo.valueChanges.subscribe((value) => {
        const isInvalid = !this.storyForm.controls.validTo.valid;
        const parseErrorText: string =
          this.storyForm.controls.validTo.errors?.matDatepickerParse?.text;
        if (value === null && isInvalid && parseErrorText === '') {
          // remove matDatepickerParse error for empty string since field is not required
          this.storyForm.controls.validTo.reset(null, {
            emitEvent: false,
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get formValidators() {
    return EFormValidators;
  }

  get tags() {
    return this.storyForm.controls.tags as FormArray;
  }

  addTag(tag: Tag): void {
    if (this.doesTagExist(tag)) {
      return;
    }
    const storyTagForm = this.fb.group({
      id: [null as number, Validators.required],
      name: ['', Validators.required],
    });
    if (tag) {
      storyTagForm.patchValue({...tag});
    }
    this.tags.push(storyTagForm);
  }

  private doesTagExist(value: Tag): boolean {
    return (
      this.tags.getRawValue().findIndex((tag) => tag.name === value.name) !== -1
    );
  }

  getOrCreateTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tagService.getOrCreateTag(value).subscribe({
        next: (tag) => {
          // ToDo handle errors
          this.addTag(tag);
        },
      });
    }
    event.chipInput.clear();
  }

  removeTag(tag: Tag): void {
    const tags = this.storyForm.value.tags;
    tags.forEach((item, index) => {
      if (item === tag) {
        this.tags.removeAt(index);
      }
    });
  }

  onAuthorSelect(event: MatAutocompleteSelectedEvent): void {
    this.storyForm.patchValue({author: event.option.value});
    this.authorControl.setValue(event.option.value.fullname, {
      onlySelf: true,
      emitEvent: false,
    });
  }

  onTagSelect(event: MatAutocompleteSelectedEvent): void {
    if (event.option?.value) {
      this.tagInput.nativeElement.value = '';
      this.addTag(event.option.value);
      this.tagControl.setValue('');
    }
  }

  /**
   * Triggered by parent component story-modal.component
   * Do not proceed if form is invalid
   */
  modalAction(modalType: EModalType): void {
    if (this.storyForm.valid) {
      if (this.story?.id && this.story.hub.ident !== this.storyForm.value.hub.ident) {
        this.modalService.openConfirmationDialog({
          title: this.translateService.instant('changeHubTitle'),
          type: EModalType.confirmationDialog,
          data: {
            message: this.translateService.instant('changeHubMessage'),
          },
          component: null,
          submitBtn: {
            label: this.translateService.instant('yes'),
            callback: (modalRef: MatDialogRef<ModalComponent>) => {
              modalRef.close(true);
              this.proceedWithModalAction(modalType);
            },
          },
          cancelBtn: {
            label: this.translateService.instant('cancel'),
            callback: (modalRef: MatDialogRef<ModalComponent>) => {
              modalRef.close(false);
              this.dialogRef.componentInstance.resetToolbarActionButtons();
            }
          },
        } as ModalData);
      } else {
        this.proceedWithModalAction(modalType);
      }
    }
  }

  private proceedWithModalAction(modalType: EModalType) {
    switch (modalType) {
      case EModalType.editStory:
        const editStory: Story = {
          ...this.story,
          ...this.storyForm.getRawValue(), // TODO check if this is correct (rawValue might return too much data)
          externalLinks: this.externalLinks,
        };
        // transform dates to server format
        editStory.validFrom = editStory.validFrom
          ? this.datePipe.transform(editStory.validFrom, 'yyyy-MM-dd')
          : null;
        editStory.validTo = editStory.validTo
          ? this.datePipe.transform(editStory.validTo, 'yyyy-MM-dd')
          : null;
        this.dataService.updateLoading(true);
        this.storyService
          .updateStory(editStory)
          .pipe(
            first(),
            finalize(() => {
              this.dataService.updateLoading(false);
              this.dialogRef.componentInstance.resetToolbarActionButtons();
            })
          )
          .subscribe({
            next: (storyData) => {
              this.dataService.updateStory(storyData);
              this.dialogRef.close({success: true});
            },
          });
        break;
      case EModalType.createStory:
        const newStory: StoryCreate = {
          ...this.storyForm.getRawValue(), // TODO check if this is correct (rawValue might return too much data)
          externalLinks: this.externalLinks,
        };
        this.dataService.updateLoading(true);
        this.storyService
          .createStory(newStory)
          .pipe(
            first(),
            finalize(() => {
              this.dataService.updateLoading(false);
              this.dialogRef.componentInstance.resetToolbarActionButtons();
            })
          )
          .subscribe({
            next: (story) => {
              this.dialogRef.close(true);
              this.notificationService.handleSuccess(
                this.translateService.instant('createStorySuccess')
              );
              this.globalService.navigate(
                `${EViewRoutes.storyOverview}${story.id}`
              );
            },
          });
        break;
    }
  }

  private initCodeTables() {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.useCase),
      this.codeTableService.getCodeTable(ECodeTables.publicationType)
    ]).subscribe(([useCases, publicationTypes]) => {
      const useCase = useCases.find(u => u.id === this.story?.useCase?.id);
      this.useCases = codeTableEntries(useCases, useCase);
      this.storyForm.patchValue({ useCase });
      // publication types
      const publicationType = publicationTypes.find(pt => pt.id === this.story?.publicationType?.id)
      this.publicationTypes = codeTableEntries(publicationTypes, publicationType);
      this.storyForm.patchValue({ publicationType });
      // hubs
      const userHubs = this.globalService.getCurrentUserData().hubs;
      const hub = userHubs.find(d => d.id === this.story?.hub?.id) || userHubs[0];
      this.hubs = codeTableEntries(userHubs, hub);
      this.storyForm.patchValue({ hub });
    });
  }

  protected updateCodeTables() {
    const values = this.storyForm.value;
    this.useCases = codeTableEntries(this.useCases, values.useCase);
    this.publicationTypes = codeTableEntries(this.publicationTypes, values.publicationType);
    this.hubs = codeTableEntries(this.hubs, values.hub);
  }

  private handleStory(story: Story): void {
    if (story) {
      this.actionButtonLabel = 'Save';
      this.story = story;
      this.storyForm.patchValue({
        name: story.name,
        author: story.author,
        validFrom: story.validFrom,
        validTo: story.validTo,
        hot: story.hot,
        info: story.info,
      });
      if (story.author) {
        this.authorControl.setValue(story.author.fullname);
      }
      // add tags
      story.tags.forEach((t) => this.addTag(t));
      this.externalLinks = [...(story.externalLinks || [])].map(l => ({...l}));
    }
  }

  private tagsRequired(formControl: AbstractControl): ValidationErrors {
    if (this.story) {
      Validators.required(formControl);
    } else {
      return null;
    }
  }

  handleExternalLinksChanged(links: ExternalLink[]) {
    this.externalLinks = links;
    this.storyForm.updateValueAndValidity();
  }

  externalLinksValidator(fb: FormGroup): ValidationErrors | null {
    if (!this?.externalLinks) return null;
    const valid = (this?.externalLinks||[]).every(l => !!l.url);
    if (valid) return  null;
    return  {
      externalLinks: true,
    };
  }

}
