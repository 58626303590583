import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ApplyColumnStateParams,
  CellClassParams,
  CellClickedEvent,
  CellEditRequestEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowSelectedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import {
  Campaign,
  CampaignAction,
  CampaignActionPaginated,
  CampaignActionService,
  CampaignService,
  CampaignStatus,
  ClientService,
  CodeTableEntry,
  GridFilterOptionsParams,
  ListParams,
  ListParamsWithSelection,
  PortfolioService,
  UserInfo,
  UserInteraction,
  UserService,
} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {DialogHeight, DialogWidth, ModalService} from 'src/app/services/modal.service';
import {ECodeTables, EModalType, EPortfolioActionStatus,} from 'src/app/util/enum';
import {GridComponent, GridResetEvent} from 'src/app/shared/grid/grid.component';
import {NotificationService} from 'src/app/services/notification.service';
import {IsCampaignEditablePipe,} from 'src/app/shared/shared.pipe';
import {CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {BehaviorSubject, combineLatest, filter, finalize, first, Observable, of, Subscription} from 'rxjs';
import {
  genBooleanColumn,
  genCodeTableColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel,
} from 'src/app/util/grid/grid-renderer.util';
import {
  GridDataProvider,
  GridSelectedItemsProvider,
  GridSelectedItemsProviderByCollection,
} from 'src/app/shared/grid/data-source';
import {EProtectedActions} from '../../../../util/protected-actions';
import {PermissionService} from '../../../../services/permission.service';
import {CustomPreviewService} from '../../../../services/custom-preview.service';
import {genActionLanguageColumn} from '../../../../shared/grid/cell-renderers/action-language.renderer';
import {genHeaderCheckboxColumn, GridSelectionUtils} from '../../../../util/grid/grid-selection.util';
import {genDialogContentColumn} from '../../../../shared/grid/cell-renderers/dialog-content.renderer';
import {genCustomContentColumn} from '../../../../shared/grid/cell-renderers/custom-content.renderer';
import {genSuitabilityColumn} from '../../../../shared/grid/cell-renderers/suitability.renderer';
import {genIconLabelColumn} from '../../../../shared/grid/cell-renderers/icon-label.renderer';
import {VIEWED_ICONS} from '../../../../shared/grid/cell-renderers/viewed.renderer';
import {ActionType, CampaignActionListUtils, ContentAction} from '../campaign-actions-list-utils';
import {genActionStatus, genExecutionAction} from '../../../../shared/grid/cell-renderers/action-status.renderer';
import {GridFilterModel} from "../../../../models/grid.model";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {ModalData} from "../../../../models/modal.model";
import {CampaignActionDocumentsComponent} from "../campaign-action-documents/campaign-action-documents.component";
import {CampaignActionToolbarComponent} from "../campaign-action-toolbar/campaign-action-toolbar.component";
import {HtmlService} from "../../../../services/html.service";

@Component({
  selector: 'app-campaign-action-list',
  templateUrl: './campaign-action-list.component.html',
})
export class CampaignActionListComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: GridComponent;
  @Output() gridRefreshed: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  selectedAssignees: string[] = [];
  @Input()
  gridSelectionUtils: GridSelectionUtils;
  @Input()
  actionToolbar: CampaignActionToolbarComponent;

  selectionCount: number = 0;

  searchValue: string;
  // intermediate subject that will communicate the grid filter model updates to the table-selection component
  filterModelSubject = new BehaviorSubject<GridFilterModel>({});

  init = false;
  showSpinner = false;

  campaign: Campaign;
  campaignRelationshipManagers: UserInfo[];
  campaignAdvisors: UserInfo[];
  assignees: UserInfo[];
  initialAssignees: UserInfo[] = [];
  executionUsers: UserInfo[];
  senders: UserInfo[];

  columnDefs: ColDef[] = [];

  gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    readOnlyEdit: true,
    enableCellTextSelection: true,
    ensureDomOrder: true,
    stopEditingWhenCellsLoseFocus: true,
    onCellEditRequest: (event) => this.handleCellEditRequest(event),
    rowClassRules: {
      'ag-row-disabled': (params) => params.data && params.data.status !== EPortfolioActionStatus.pending,
      'ag-row-warn': (params) => params.data && params.data.client.closed || params.data && params.data.portfolio.closed,
    },
    getRowId: params => params.data.id,
    masterDetail: true,
    detailRowAutoHeight: true,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
    onRowSelected: (event: RowSelectedEvent) => {
      this.gridSelectionUtils.setSelection(
        event?.data?.id,
        event?.data,
        event?.node?.isSelected()
      );
    },
    onSortChanged: (event: SortChangedEvent) => {
      this.selectedSort = event.api.getColumn('id')?.getSort();
    },
  };

  data: GridDataProvider;
  selectedSort: string;
  selectAllProvider: GridSelectedItemsProvider;
  selectAllPreselectionProvider: GridSelectedItemsProvider;
  selectAllByCollectionPreselectionProvider: GridSelectedItemsProviderByCollection;
  gridApi: GridApi;

  private languages: CodeTableEntry[];
  private subscriptions: Subscription[] = [];
  private listUtils: CampaignActionListUtils;

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly campaignActionsService: CampaignActionService,
    protected readonly globalService: GlobalService,
    protected readonly modalService: ModalService,
    protected readonly notificationService: NotificationService,
    protected readonly campaignService: CampaignService,
    protected readonly codeTableService: CodeTableService,
    protected readonly isCampaignEditablePipe: IsCampaignEditablePipe,
    protected readonly dataService: DataService,
    protected readonly permissionService: PermissionService,
    protected readonly customPreviewService: CustomPreviewService,
    protected readonly clientService: ClientService,
    protected readonly portfolioService: PortfolioService,
    protected readonly htmlService: HtmlService,
    protected readonly userService: UserService,
    private zone: NgZone,
  ) {
  }

  ngOnInit(): void {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.language)
    ]).subscribe(([languages]) => {
      this.languages = languages;
    });
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          if (!this.init) {
            this.data = this.fetchData.bind(this);
            this.selectAllProvider = (filter?: string, search?: string) => {
              return this.campaignActionsService.getCampaignActionsSelection(this.campaign.id, false, filter, search);
            }
            this.selectAllPreselectionProvider =
              this.campaignActionsService.getCampaignActionsSelection.bind(
                this.campaignActionsService,
                this.campaign.id,
                true
              );
            this.selectAllByCollectionPreselectionProvider =
              this.campaignActionsService.getCampaignActionsSelectionByUserCollection.bind(
                this.campaignActionsService,
                this.campaign.id,
              );
            this.listUtils = new CampaignActionListUtils(
              this.zone,
              this.campaign,
              this.notificationService,
              this.modalService,
              this.translateService,
              this.permissionService,
              this.isCampaignEditablePipe,
              this.customPreviewService,
              this.campaignActionsService,
              null,
              this.userService,
            );
            this.htmlService.waitFor(
              () => !!this.actionToolbar,
              () => {
                this.actionToolbar.options = {
                  gridSelectionUtils: this.gridSelectionUtils,
                  selectAllProvider: this.selectAllProvider,
                  selectAllPreselectionProvider: this.selectAllPreselectionProvider,
                  selectAllByCollectionPreselectionProvider: this.selectAllByCollectionPreselectionProvider,
                  selectionCleared: () => this.deselectAll(),
                  refreshTable: (force, redrawRows) => {
                    if (!this.grid) return;
                    this.grid.refresh(false, redrawRows);
                    this.onGridRefreshed();
                  },
                  selectionProcessing: loading => this.toggleOverlay(loading),
                  searchText: () => this.searchValue,
                };
              }
            );
            this.init = true;
            this.initColumnDefs();
          } else {
            this.refreshGrid();
          }
        }),
      this.permissionService.user$.subscribe(() => {
        if (this.gridApi) {
          // set timeout to let dynamic user update enabled/disabled
          setTimeout(() => {
            this.selectedAssignees = [];
            this.updateAssigneesBasedOnDynamicUsers();
            this.presetAssigneeFilter(this.gridApi);
          }, 0);
        }
      }),
    );
  }

  private fetchData(gridParams: ListParams): Observable<CampaignActionPaginated> {
    // avoid fetching data until assignees are loaded
    if (!this.assignees) return of({
      pageItems: [],
      count: 0
    });
    const params = {
      ...gridParams,
      selectedItems: this.selectedSort ? this.gridSelectionUtils.getSelectedValues().map((item) => item.id) : [],
      selectedSort: this.selectedSort,
    } as ListParamsWithSelection;
    this.toggleOverlay(true)
    return this.campaignActionsService.getCampaignActions(
      this.campaign.id,
      params
    ).pipe(finalize(() => this.toggleOverlay(false)));
  }

  private initColumnDefs(): void {
    const isLaunched = (this.campaign.status === CampaignStatus.LAUNCHED);
    const isFrozen = (this.campaign.status === CampaignStatus.FROZEN);
    const isEditableCampaign = this.isCampaignEditablePipe.transform(this.campaign.status);
    const isCidFilterableSortable = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    const langColumns = !isFrozen && isEditableCampaign ?
      [
        {
          ...genCodeTableColumn({
            field: 'campaignPortfolio.portfolio.preferredLanguage',
            dtoField: 'portfolio.preferredLanguage',
            headerName: this.translateService.instant('languagePreferred'),
            filterValues: () => this.languages,
          }),
        },
        {
          ...genActionLanguageColumn({
            field: 'language.ident',
            headerName: this.translateService.instant('language'),
            campaignStatus: this.campaign.status,
          }),
          ...genCodeTableColumn({
            field: 'language',
            headerName: this.translateService.instant('languageActual'),
            filterValues: () => this.languages,
          }),
          sortable: true,
          suppressHeaderMenuButton: true,
          singleClickEdit: true,
          editable: (params: any) => this.listUtils.isDetailLangCellEditable(params),
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: (params) => this.listUtils.getLanguageEditParams(params),
          cellClass: (params: any) => this.listUtils.isDetailLangCellEditable(params) ? 'editable-cell' : '',
        }
      ] :
      [
        {
          ...genCodeTableColumn({
            field: 'campaignPortfolio.portfolio.preferredLanguage',
            dtoField: 'portfolio.preferredLanguage',
            headerName: this.translateService.instant('language'),
            filterValues: () => this.languages,
          }),
        }
      ];
    const channelColumns = !isFrozen && isEditableCampaign ? [{
      ...genDialogContentColumn({
        field: 'channel.type.name',
        labelValue: (data: CampaignAction) => data.channel?.type?.name,
        headerName: this.translateService.instant('channel'),
        iconGetter: (data) => (data.content || data.status !== EPortfolioActionStatus.pending) ? 'view' : 'edit_m',
      }),
      ...genCodeTableColumn({
        field: 'channel.type',
        headerName: this.translateService.instant('channel'),
        observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
      }),
      suppressHeaderMenuButton: true,
      onCellClicked: (event: CellClickedEvent) =>
        this.listUtils.handleChannelCellClick(
          event.data, ActionType.CampaignAction, this.updateAction.bind(this), true
        ),
      cellClass: (params: CellClassParams) => 'editable-cell',
    }] : [{
      ...genCodeTableColumn({
        field: 'channel.type',
        headerName: this.translateService.instant('channel'),
        observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
      }),
    }];
    const colDefs = [
      isEditableCampaign ?
        {
          ...genHeaderCheckboxColumn(
            this.gridSelectionUtils,
            true, 0, 'id', true,
            this.translateService.instant('sortBySelected'),
          ),
          lockVisible: true,
        }
        : undefined,
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'client.fullName',
          headerName: this.translateService.instant('client'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.campaignService.getGridFilterOptions(data),
            autoCompleteField: 'fullName',
            autoCompleteContextId: this.campaign.id,
          },
        }),
        floatingFilter: isCidFilterableSortable,
        sortable: isCidFilterableSortable,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'portfolio.bpNumber',
          headerName: this.translateService.instant('bpNumber'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.campaignService.getGridFilterOptions(data),
            autoCompleteField: 'bpNumber',
            autoCompleteContextId: this.campaign.id,
          },
          valueFormatterFunc: (r) => r.data.portfolio.bpNumber,
          filterParamsInfo: { customPath: 'campaignPortfolio.portfolio.bpNumber' }
        }),
        floatingFilter: isCidFilterableSortable,
        sortable: isCidFilterableSortable,
      },
      {
        ...genIconLabelColumn({
          field: 'portfolio.number',
          headerName: this.translateService.instant('portfolioNumber'),
          labelCallback: (data: CampaignAction) =>
            this.listUtils.openPortfolioDetailsModal(data.portfolio),
          label: (data: CampaignAction) => data.portfolio.number,
          tooltip: 'showDetails',
          icon: (data: CampaignAction) => data.portfolioContainsBuyProducts ? 'warning' : undefined,
        }),
        filterParams: {
          customPath: 'campaignPortfolio.portfolio.number',
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.campaignService.getGridFilterOptions(data),
            autoCompleteField: 'number',
            autoCompleteContextId: this.campaign.id,
          },
        },
        sortable: true,
      },
      {
        ...genBooleanColumn(
          'portfolioContainsBuyProducts',
          this.translateService.instant('portfolioContainsBuyProducts'),
          this.translateService,
          'campaignPortfolio.portfolioContainsBuyProducts'
        ),
        hide: true,
      },
      {
        ...genCodeTableColumn({
          field: 'clientRole',
          headerName: this.translateService.instant('clientRole'),
          observable: this.codeTableService.getCodeTable(ECodeTables.clientRole),
          customPath: 'clientRole.id'
        }),
        floatingFilter: true,
        hide: true,
      },
      ...langColumns,
      ...channelColumns,
      isLaunched ? {
        ...genCustomContentColumn({
          field: 'hasContentOverride',
          headerName: '',
          hidden: (data: any) =>
            this.campaign.contents?.length === 0 ||
            !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent) ||
            !data.hasCidPermission,
          callback: (rawData: CampaignAction, action: string) => {
            // fetch the latest data from the grid
            const data = this.gridApi.getRowNode(rawData.id + '').data;
            const contentAction: ContentAction = {
              id: data.id,
              type: ActionType.CampaignAction,
              campaignId: this.campaign.id,
              language: data.language,
              channel: data.channel,
              content: data.content,
            }
            // when deleting a custom content some renderers are not correctly updating their content,
            // in this case we need to refresh the grid
            if (action === 'delete') {
              this.listUtils.handleCustomContentAction(contentAction, action, false, this.refreshGrid.bind(this));
            } else {
              this.listUtils.handleCustomContentAction(contentAction, action, false, this.updateAction.bind(this));
            }
          },
          filterParamsInfo: {
            customPath: 'content.id'
          },
        }),
        floatingFilter: false,
        sortable: true,
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true,
      } : undefined,
      !isFrozen ? {
        ...genDialogContentColumn({
          field: 'sender.username',
          labelValue: (data: CampaignAction) => usernameValueLabel(data.sender),
          headerName: this.translateService.instant('sender'),
        }),
        ...genUserEnumColumn(
          'sender.username',
          this.translateService.instant('sender'),
          this.fetchSenders.bind(this),
          () => this.senders
        ),
        valueFormatter: (r) =>
          usernameValueLabel(r.data.sender),
        suppressHeaderMenuButton: true,
        onCellClicked: (event: CellClickedEvent) =>
          this.listUtils.handleSenderCellClick(event.data, ActionType.CampaignAction, () => this.refreshGrid()),
        cellClass: (params: CellClassParams) => this.listUtils.isSenderCellEditable(params.data) ? 'editable-cell' : '',
        sortable: true,
      } : {
        ...genUserEnumColumn(
          'sender.username',
          this.translateService.instant('sender'),
          this.fetchSenders.bind(this),
          () => this.senders
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.sender),
      },
      {
        ...genSuitabilityColumn({
          translateService: this.translateService,
          field: 'suitability',
          stateInfo: (data: CampaignAction) => ({
            state: data.combinedSuitabilityState,
            campaignActionId: data.id,
          }),
          headerName: this.translateService.instant('suitability'),
          callback: (data: any) => this.listUtils.openClientSuitability(data),
          filterParamsInfo: {
            customPath: 'combinedSuitabilityState'
          }
        }),
        suppressHeaderMenuButton: true,
      },{
        ...genIconButtonColumn({
          icon: data => VIEWED_ICONS[data.viewed.ident],
          headerName: this.translateService.instant('viewed'),
          tooltip: this.translateService.instant('documents'),
          callback: (data: CampaignAction) => {
            if (data.viewed.ident === 'yes') this.showDocuments(data);
          },
          disabled: (data) => (data.viewed.ident !== 'yes')
        }),
        sortable: false,
        width: 60,
        maxWidth: 150,
      },
      {
        ...genUserEnumColumn(
          'assignee.username',
          this.translateService.instant('assignee'),
          this.fetchAssignees.bind(this),
          () => this.assignees
        ),
        valueFormatter: (r) =>
          usernameValueLabel(r.data.assignee),
        hide: false,
      },
      {
        ...genActionStatus(
          this.translateService,
          'status',
          this.translateService.instant('status'),
          isEditableCampaign
        ),
        hide: true,
      },
      genExecutionAction(
        this.translateService, 'executionAction',
        this.translateService.instant('executedAction'),
        true,
      ),
      {
        ...genUserEnumColumn(
          'executionUser.username',
          this.translateService.instant('executedBy'),
          this.fetchExecutionUsers.bind(this),
          () => this.executionUsers
        ),
        valueFormatter: (r) =>
          usernameValueLabel(r.data.executionUser),
        hide: true,
      },
      {
        ...genUserEnumColumn(
          'portfolio.relationshipManager.username',
          this.translateService.instant('relationshipManager'),
          this.fetchCampaignRelationshipManagers.bind(this),
          () => this.campaignRelationshipManagers,
          'campaignPortfolio.portfolio.relationshipManager.username'
        ),
        valueFormatter: (r) =>
          usernameValueLabel(r.data.portfolio.relationshipManager),
        hide: true,
      },
      {
        ...genUserEnumColumn(
          'portfolio.advisor.username',
          this.translateService.instant('advisor'),
          this.fetchCampaignAdvisors.bind(this),
          () => this.campaignAdvisors,
          'campaignPortfolio.portfolio.advisor.username'
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.portfolio.advisor),
        hide: true,
      },
      {
        ...genTextColumn(
          'portfolio.bpName',
          this.translateService.instant('bpName'),
          (r) => r.data.portfolio.bpName,
          {
            customPath: 'campaignPortfolio.portfolio.bpName'
          }
        ),
        sortable: isCidFilterableSortable,
        floatingFilter: isCidFilterableSortable,
        hide: true,
      },
    ];
    this.columnDefs = colDefs.filter(t => t);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.dataService.clearSelection();
  }

  onGridRefreshed(): void {
    this.gridRefreshed.emit();
  }

  refreshGrid(purgeData: boolean = false): void {
    this.grid?.refresh(purgeData);
  }

  private updateLanguage(event: CellEditRequestEvent): void {
    if (event.newValue !== event.oldValue.name) {
      const newLanguage = this.languages.find(
        (language) => language.name === event.newValue
      );
      this.showSpinner = true;
      this.campaignActionsService
        .updateCampaignActionLanguage(event.data.id, newLanguage.id)
        .pipe(first())
        .subscribe({
          next: (data: CampaignAction) => this.updateAction(data),
          complete: () => (this.showSpinner = false),
        });
      this.userService.recordUserInteraction(
        newLanguage.ident == 'en' ? UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETOEN
          : newLanguage.ident == 'de' ? UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETODE
            : UserInteraction.CHANGEDCAMPAIGNACTIONLANGUAGETOOTHER
      ).pipe(first()).subscribe();
    }
  }

  /**
   * Update the action in the grid, we can perform a transaction to update the action
   * since this is a flat-list grid
   */
  private updateAction(action: CampaignAction): void {
    this.gridApi.applyServerSideTransaction({ update: [action] })
    // update the selected items if the action is selected
    this.gridSelectionUtils.updateSelection(action.id, action);
  }

  private fetchAssignees(params: any) {
    if (this.assignees) {
      params.success(this.assignees.map((d) => d.username));
    } else {
      this.campaignActionsService
        .getCampaignActionsAssignees(this.campaign.id)
        .subscribe((data) => {
          this.assignees = data;
          this.initialAssignees = this.assignees;
          this.updateAssigneesBasedOnDynamicUsers();
          params.success(this.assignees.map((d) => d.username));
        });
    }
  }

  private updateAssigneesBasedOnDynamicUsers() {
    const curDynamicUsers: UserInfo[] = this.permissionService.userRoleData.dynamicUsers
      .filter(u => u.enabled).filter(Boolean)
      .map(u => ({id: u.id, username: u.username, fullname: u.fullname}));
    this.assignees = this.initialAssignees.concat(
      curDynamicUsers.filter(u => !this.initialAssignees.find(i => i.username === u.username)));
  }

  private fetchExecutionUsers(params: any) {
    if (this.executionUsers) {
      params.success(this.executionUsers.map((d) => d.username));
    } else {
      this.campaignActionsService
        .getCampaignActionsExecutors(this.campaign.id)
        .subscribe((data) => {
          this.executionUsers = data;
          params.success(this.executionUsers.map((d) => d.username));
        });
    }
  }
  private fetchSenders(params: any) {
    if (this.senders) {
      params.success(this.senders.map((d) => d.username));
    } else {
      this.campaignService.getSelectedSenders(this.campaign.id)
        .subscribe((data) => {
          this.senders = data;
          params.success(this.senders.map((d) => d.username));
        });
    }
  }

  private fetchCampaignAdvisors(params: any) {
    this.campaignService
      .getCampaignPortfoliosAdvisors(this.campaign.id)
      .subscribe((data) => {
        this.campaignAdvisors = data;
        params.success(data.map((d) => d.username));
      });
  }

  private fetchCampaignRelationshipManagers(params: any) {
    this.campaignService
      .getCampaignPortfoliosRelationshipManagers(this.campaign.id)
      .subscribe((data) => {
        this.campaignRelationshipManagers = data;
        params.success(data.map((d) => d.username));
      });
  }

  private gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.htmlService.waitFor(
      () => !!this.actionToolbar,
      () => this.actionToolbar.gridApi = event.api
    );
    // force filter-preset
    this.gridApi.showLoadingOverlay();
    this.fetchAssignees({
      success: () => {
        this.presetAssigneeFilter(event.api);
        this.gridApi.hideOverlay();
      },
    });
    this.fetchSenders({
      success: () => {}
    });
    this.applyDefaultSorting(event.api);
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultSorting(event.api);
    this.selectedAssignees = [];
    this.presetAssigneeFilter(event.api);
  }

  private applyDefaultSorting(gridApi: GridApi) {
    // set default sort if nothing is set
    if (gridApi.getColumnState().findIndex((c) => c.sort) === -1) {
      const columnState: ApplyColumnStateParams = {
        state: [
          {
            colId: 'portfolio.bpNumber',
            sort: 'asc',
          },
        ],
      };
      gridApi.applyColumnState(columnState);
    }
  }

  private presetAssigneeFilter(gridApi: GridApi) {
    const currentUsernames = [
      this.permissionService.userRoleData.username,
      ...this.permissionService.userRoleData.dynamicUsers
        .filter(u => u.enabled)
        .map(u => u.username)
    ].filter(Boolean);
    this.listUtils.setAssigneeFilter(
      gridApi,
      currentUsernames,
      'assignee.username',
      this.assignees,
      this.selectedAssignees
    ).then(filter => {
      if (!filter) {
        this.assignees = [];
        this.grid.refresh();
      }
    });
  }

  private handleCellEditRequest(event: CellEditRequestEvent): void {
    if (event.colDef.field === 'language') {
      this.updateLanguage(event);
    }
  }

  getAssigneeFilterValues(): Promise<string[]> {
    if (!this.gridApi) return Promise.resolve([]);
    return this.gridApi.getColumnFilterInstance('assignee.username').then(filter => {
      return Array.from((filter as any).getValueModel().selectedKeys);
    });
  }

  toggleOverlay(loading: boolean) {
    if (loading) {
      this.gridApi.showLoadingOverlay();
    } else {
      this.gridApi.hideOverlay();
    }
  }

  showDocuments(campaignAction: CampaignAction) {
    this.campaignActionsService.getClientDocuments(campaignAction.id)
      .subscribe(documents => {
        if (documents.documents && documents.documents.length === 0) {
          this.notificationService.handleInfo(this.translateService.instant("documentsNotFound"))
          return;
        }
        const modalData: ModalData = {
          type: EModalType.clientAccessedDocuments,
          title: EModalType.clientAccessedDocuments,
          data: {
            campaignAction,
            documents: documents.documents,
          },
          cancelBtn: {
            label: this.translateService.instant('close')
          },
          component: CampaignActionDocumentsComponent,
        };
        this.modalService.openDefaultDialog(
          modalData,
          null,
          false,
          null,
          DialogWidth.HALF,
          DialogHeight.HALF
        )
      });
  }

  deselectAll() {
    this.gridApi?.deselectAll();
  }

  selectAllClicked(selected: boolean) {
    this.actionToolbar?.selectAllClicked(selected);
  }
}
