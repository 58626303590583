import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize, first, Subscription } from 'rxjs';
import {
  CampaignStatus,
  CampaignUpdate,
  Story,
  StoryService,
} from 'src/app/api/core';
import { CampaignDetailsFormComponent } from 'src/app/campaign/views/campaign-overview/campaign-details-form/campaign-details-form.component';
import { ModalData } from 'src/app/models/modal.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import {
  ModalService,
} from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { EModalType, EViewRoutes } from 'src/app/util/enum';
import { EProtectedActions } from '../../../../util/protected-actions';
import {
  PageBarButton,
} from '../../../../services/page.bar.service';
import { PermissionService } from '../../../../services/permission.service';

/**
 * Component for the campaign overview toolbar
 */
@Component({
  selector: 'app-story-overview-toolbar',
  templateUrl: './story-overview-toolbar.component.html',
})
export class StoryOverviewToolbarComponent implements OnInit, OnDestroy {
  story: Story;
  deleteButtonTooltip: string;
  storyButtons: PageBarButton[] = [
    {
      icon: 'open_in_new',
      text: 'createCampaign',
      color: 'primary',
      click: () => {
        this.onCreateCampaign();
      },
      show: () => {
        return this.isProtected(EProtectedActions.createCampaignOnStory);
      },
    },
    {
      icon: 'delete',
      text: 'deleteStory',
      color: 'warn',
      tooltip: () => {
        return this.deleteButtonTooltip;
      },
      click: () => {
        this.onDeleteStory();
      },
      show: () => {
        return this.isProtected(EProtectedActions.deleteStory);
      },
      disabled: () => {
        return !this.story?.deletable;
      },
    },
  ].reverse();

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly dataService: DataService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly storyService: StoryService,
    private readonly notificationService: NotificationService,
    private readonly globalService: GlobalService,
    private readonly permissionService: PermissionService
  ) {
    this.subscriptions.push(
      this.dataService.story$
        .pipe(filter((story) => !!story))
        .subscribe((story) => {
          this.story = story;
          this.deleteButtonTooltip = this.story.deletable
            ? this.translateService.instant('deleteStory')
            : this.translateService.instant('deleteStoryForbidden');
        })
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get protectedActions() {
    return EProtectedActions;
  }

  onCreateCampaign() {
    const modalData: ModalData = {
      type: EModalType.editCampaign,
      title: EModalType.editCampaign,
      data: {
        storyId: this.story.id,
        campaign: {
          name: this.story.name,
          useCase: this.story?.useCase,
          publicationType: this.story?.publicationType,
          hub: this.story?.hub,
          validFrom: this.story?.validFrom,
          validTo: this.story?.validTo,
          info: this.story?.info,
          externalLinks: (this.story?.externalLinks || []).map((link) => ({
            ...link,
            id: null,
          })),
        } as CampaignUpdate,
        status: CampaignStatus.DRAFT,
      },
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: CampaignDetailsFormComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(modalData);

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success && result.campaign) {
        this.globalService.navigate(
          EViewRoutes.campaignOverview + result.campaign.id
        );
        this.notificationService.handleSuccess(
          this.translateService.instant('createCampaignSuccess')
        );
      }
    });
  }

  onDeleteStory(): void {
    const modalData: ModalData = {
      title: EModalType.deleteStory,
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('deleteStoryWarning'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.dataService.updateLoading(true);
          this.storyService
            .deleteStory(this.story.id)
            .pipe(
              first(),
              finalize(() => {
                this.dataService.updateLoading(true);
                modalRef.componentInstance.resetToolbarActionButtons();
              })
            )
            .subscribe({
              next: () => {
                modalRef.close(true);
                this.globalService.navigate(EViewRoutes.stories);
                this.notificationService.handleSuccess(
                  this.translateService.instant('storyDeleteSuccess')
                );
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  private isProtected(action: EProtectedActions): boolean {
    return this.permissionService.hasAnyPermission(action);
  }
}
